import React from 'react';
import BaseAction from './BaseAction';
import IntegrationsAPI from "../../../api/integrations";

class SendTelegramNotificationAction extends BaseAction {

  onFindByName = () => {
    IntegrationsAPI.getTelegramChatId(this.state.action?.channel).then(({success, data}) => {
        this.updateAction('chatId', data ? String(data) : '');
        this.setState({ error: data ? '' :
                'Could not find channel id.\n' +
                '1. Make sure channel name is correct.\n' +
                '2. Add the Appguard_bot\n' +
                '3. Send any message in the channel\n' +
                '4. Try again'});
    });
 };

  renderAction(action) {
    return <div>
      <div style={{display: 'flex', whiteSpace: 'nowrap'}}>
        {this.renderInput(action, 'Chat ID', 'chatId')}
        <div style={{display: 'inline-flex', marginLeft: 30}}>
          <button className="btn btn-primary" onClick={this.onFindByName}
                  style={{width: 200}}>Find by channel name</button>
        </div>
        {this.renderInput(action, '', 'channel')}
      </div>
        <pre style={{color: 'red'}}>{this.state.error}</pre>
      {this.renderTextarea(action, 'Text', 'text')}
    </div>;
  }
}

export default SendTelegramNotificationAction;
