import React, { useState } from 'react'
// import {DialogActions, makeStyles} from "@material-ui/core";
import { Buttons } from "../index";
import general from "../../../utils/general";
import './Form.scss';

export function useForm(initialValues, validateOnChange = false, validate) {

  const [values, setValues] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({});
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { type, name, value, checked } = e.target
    const savedValue = type === 'checkbox' ? !values[name] : value;
    setValues({
      ...values,
      [name]: savedValue
    })
    if (validateOnChange) {
      validate({ [name]: savedValue })
    }

    if (initialValues[name] !== savedValue) {
      setDirtyFields({...dirtyFields, [name]: savedValue});
      setIsDirty(true);
    } else {
      const dirty = { ...dirtyFields };
      delete dirty[name];
      setDirtyFields(dirty);
      setIsDirty(!!Object.keys(dirty).length);
    }
  }

  const resetForm = (newValues = initialValues) => {
    setValues(newValues);
    setDirtyFields({});
    setIsDirty(false);
    setErrors({})
  }

  const updateDirtyFields = (changes) => {
    if (changes && Object.keys(changes).length) {
      setDirtyFields({...dirtyFields, ...changes});
      setIsDirty(true);
    }
  }

  return {
    values,
    setValues,
    errors,
    setErrors,
    isDirty,
    dirtyFields,
    handleInputChange,
    resetForm,
    updateDirtyFields
  }
}

export function Form(props) {
  const { children, onSave, onCancel } = props;
  return (
    <form onSubmit={onSave} autoComplete="off" >
      {children}
      <div className='form-footer'>
        <Buttons.Cancel onClick={onCancel} />
        <Buttons.Save type="submit" />
      </div>
    </form>
  )
}
