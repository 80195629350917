import PropTypes from 'prop-types';
import React from 'react';
import '../assets/styles/components/_save-changes-bar.scss';
import { Buttons } from './core';

const SaveChangesBar = ({HasChanges: hasChanges, DisableSave: disableSave, Message: message, OnSave: onSave, OnCancel: onCancel}) => {
  return (
    <div className={"save-changes-bar" + (message || hasChanges ? " show-footer" : "")}>
      <div>
        {hasChanges && <>
          <Buttons.Save disabled={disableSave} onClick={onSave} />
          <Buttons.Cancel onClick={onCancel} />
        </>
        }
        {message && <em>{message}</em>}
      </div>
    </div>
  );
};

SaveChangesBar.propTypes = {
  DisableSave: PropTypes.bool,
  HasChanges: PropTypes.bool.isRequired,
  Message: PropTypes.string,
  OnSave: PropTypes.func.isRequired,
  OnCancel: PropTypes.func
};

export default SaveChangesBar;
