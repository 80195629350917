/* eslint-disable no-param-reassign */
class Converters {
  static toCurrencyString(num = 0, doEstimate = true) {
    if (isNaN(num))
      num = 0;
    let formattedNum = num;
    let sign = '';
    if (doEstimate) {
      const absNumber = Math.abs(num);
      if(absNumber > 999 && absNumber < 1000000){
          formattedNum = (num/1000).toFixed(0);
          sign = 'K'; // convert to K for number from > 1000 < 1 million
      } else if(absNumber >= 1000000 && absNumber < 1000000000){
          formattedNum = (num/1000000);
          sign = 'M'; // convert to M for number from > 1 million
      } else if(absNumber >= 1000000000){
          formattedNum = (num/1000000000);
          sign = 'B'; // convert to B for number from > 1 billion
      }
    }
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(formattedNum) + sign;
  }

  static toDateString(date, options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) {
      const locale = ['en-US', 'he'];
      return new Date(date).toLocaleDateString(locale, options);
  }

  static thousandSeparator(value) {
      if (!isNaN(value)) return (+value).toLocaleString('en-US');
      return value;
  }

  static toPercentage(value) {
    if (!isNaN(value))
      return `${Math.floor(value*10)/10}%`;
    return value;
  }
}

export default Converters;
