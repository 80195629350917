import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import MetadataAPI from "../../api/metadata";
import PageSpinner from "../PageSpinner";
import Editable from "../../components/editors/Editable";
import CriteriaDrawer from "../editors/CriteriaDrawer";
import ActionsDrawer from "../editors/ActionsDrawer";
import SaveChangesBar from "../SaveChangesBar";
import general from "../../utils/general";
import '../../assets/styles/pages/flow-editor.scss';

class FlowEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      flow: [],
      errors: [],
      editedValues: {},
      error: null,
      availableActionProviders: []
    };
  }

  componentDidMount() {
    this.fetchFlow(this.props.match.params.ruleId);
    this.fetchAvailableActionProviders();
  }

  fetchFlow(id) {
    MetadataAPI.getIncidentFlow(id).then(({success, data: flow}) => {
      if (success) {
        flow.disabled = flow.isDisabled;
        this.setState({editedValues: general.clone(flow), flow, loading: false});
      }
    });
  }

  fetchAvailableActionProviders() {
    MetadataAPI.getAvailableActionProviders().then(({success, data: providers}) => {
      if (success) {
        this.setState({availableActionProviders: providers});
      }
    });
  }

  getValuesToUpdate() {
    if (general.isDeepEqual(this.state.flow, this.state.editedValues))
      return false;

    return Object.keys(this.state.flow)
        .filter(key => {
          return !general.isDeepEqual(this.state.flow[key], this.state.editedValues[key]);
        })
        .reduce((res, key) => {
          res[key] = this.state.editedValues[key];
          return res;
        }, {});
  }

  saveChanges = () => {
    const flowValues = this.getValuesToUpdate();
    return MetadataAPI.updateIncidentFlow(this.state.flow.id, flowValues).then(result => {
      // TODO: Replace newly saved rules with current ones in the state
      // this.fetchAll();
      if (result.success) {
        const flow = result.data;
        flow.disabled = flow.isDisabled;
        this.setState({editedValues: flow, flow});
      }
      else {
        this.setState({ error: result.error });
      }
      return true;
    });
  }

  toggleCheckboxValue(fieldName) {
    const editedValues = general.clone(this.state.editedValues);
    editedValues[fieldName] = !editedValues[fieldName];
    this.setState({editedValues});
  }

  onValueChange = (event) => {
    const editedValues = general.clone(this.state.editedValues);
    if (['actions', 'criteria'].includes(event.target.name)) {
      if (this.isJson(event.target.value)) {
        editedValues[event.target.name] = JSON.parse(event.target.value);
        this.setState({editedValues});
      }
    }
    else {
      editedValues[event.target.name] = event.target.value;
      this.setState({editedValues});
    }
  };

  onCriteriaChange = (criteria) => {
    const editedValues = general.clone(this.state.editedValues);
    editedValues.criteria = criteria;
    this.setState({ editedValues });
  };

  onActionsChange = (actions) => {
    const editedValues = general.clone(this.state.editedValues);
    editedValues.actions = actions;
    this.setState({ editedValues });
  };

  isJson(str) {
    try {
      const obj = JSON.parse(str);
      if (obj && typeof obj === `object`) {
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  }

  render() {
    return <main className="flow-editor">
      {this.state.loading ? <PageSpinner /> : <>
        <Container>
          <Row>
            <Col>
              <Editable
                type="text"
                name="name"
                label="Name"
                value={this.state.editedValues.name}
                placeholder="Name"
                className="name"
                onChange={this.onValueChange} />
            </Col>
            <Col className="align-bottom">
              <label form="chkActive"><input id="chkActive" name="chkActive" type="checkbox" onChange={() => this.toggleCheckboxValue('disabled')} checked={!this.state.editedValues.disabled} /> Active</label>
            </Col>
            <Col>
              <Editable
                  type="number"
                  name="order"
                  label="Order"
                  value={this.state.editedValues.order}
                  placeholder=""
                  className=""
                  onChange={this.onValueChange} />
            </Col>
          </Row>
          { this.state.flow.lastError &&
            <Row>
              <div className="error">Last error: {this.state.editedValues.lastError}</div>
            </Row>
          }
          <Row>
            <Col>
              <Editable
                  type="textarea"
                  name="description"
                  label="Description"
                  value={this.state.editedValues.description}
                  placeholder=""
                  className="description"
                  onChange={this.onValueChange} />
            </Col>
          </Row>
          <Row>
            <Col className="align-bottom">
              <label form="continueOnSuccess">
              <input id="continueOnSuccess" type="checkbox" onChange={() => this.toggleCheckboxValue('continueProcessingOnSuccess')} value={!this.state.editedValues.continueProcessingOnSuccess} />
                Continue processing on success</label>
            </Col>
            <Col>
              <Editable
                  type="text"
                  name="minTimeBetweenLogs"
                  label="Min time between logs"
                  value={this.state.editedValues.minTimeBetweenLogs}
                  placeholder="6h"
                  className="minTimeBetweenLogs"
                  onChange={this.onValueChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Editable
                  type="textarea"
                  name="criteria"
                  label="Criteria"
                  value={JSON.stringify(this.state.editedValues.criteria, undefined, 2)}
                  onChange={this.onValueChange}  />
            </Col>
          </Row>
          <Row className='drawer-cnt'>
            <Col>
              <CriteriaDrawer criteria={this.state.editedValues.criteria} onChange={this.onCriteriaChange} />
            </Col>
          </Row>
            <Row>
              <Col>
                <Editable
                    type="textarea"
                    name="actions"
                    label="Actions"
                    value={JSON.stringify(this.state.editedValues.actions, undefined, 2)}
                    onChange={this.onValueChange}  />
              </Col>
          </Row>
          <div className='drawer-cnt'>
              <ActionsDrawer availableProviders={this.state.availableActionProviders} actions={this.state.editedValues.actions} onChange={this.onActionsChange} />
          </div>
        </Container>
      </>}

      <SaveChangesBar HasChanges={this.hasChanges()} DisableSave={this.hasError() || !!this.state.error} Message={this.state.error} OnCancel={() => this.reset()} OnSave={this.saveChanges} />
    </main>;
  }

  hasChanges() {
    return !general.isDeepEqual(this.state.flow, this.state.editedValues);
  }

  hasError() {
    return false;
  }

  reset() {
    this.setState({editedValues: general.clone(this.state.flow), error: null});
  }


}

export default FlowEditor;
