import React from 'react';
import * as Icons from '../icons';
import Button from '@material-ui/core/Button';

const SaveButton = ({ text='Save', type, onClick, disabled, color = 'primary' }) => {
  return <Button
    variant="contained"
    color={color}
    disabled={disabled}
    startIcon={<Icons.Check />}
    onClick={onClick}
    type={type}
  >
    {text}
  </Button>
};


export default SaveButton;
