import React, { useState, useEffect } from 'react';
import { verifyEmail } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import Logo from '../../assets/img/logo/logo-dark-theme.png';
import { NavLink }  from 'react-router-dom';

function Verify(props) {

  const verification = props.match.params.id;

  const [validateEmailMessage, setValidateEmailMessage] = useState("מוודא...");
  const [hasError, setHasError] = useState(null);

  useEffect(() => {
    verifyEmail(verification).then(({success, data, error}) => {
      setHasError(!success);
      setValidateEmailMessage((success ? 'Email validated successfully' : 'Link in not valid, or email already validated'));
    });
  }, [verification]);

  return (
    <div className="login-form">
      <form>
        <h3>Verify email address</h3>
        <img src={Logo} alt="AppGuard" />
          <div className={"reset-success" + (hasError ? ' txt-danger' : '')}>{validateEmailMessage}<br /><br />
          <NavLink exact to="/login">Log into your account</NavLink>
          </div>
        </form>
    </div>
  );
}

export default Verify;
