import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { lightTheme } from './themes';
import { Home, Users, Integrations, IncidentFlows, Flows, FlowEditor, Login, Register,
  ForgotPassword, ResetPassword, VerifyUser, NotFoundPage, Settings } from './components/pages';
import OAuth2Authorize from './components/pages/components/integrations/OAuth2Authorize';
import OAuth2Redirect from './components/pages/components/integrations/OAuth2Redirect';
import { verifyTokenAsync } from './asyncActions/authAsyncActions';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import EnvironmentTag from './components/EnvironmentTag';
import PageSpinner from './components/PageSpinner';
import SideBar from './components/layout/side-bar/SideBar';

import './App.scss';

const theme = createMuiTheme(lightTheme);

function App() {
  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { authLoading, isAuthenticated } = authObj;

  // verify token on app load
  useEffect(() => {
    dispatch(verifyTokenAsync());
  }, [dispatch]);

  // checking authentication
  if (authLoading) {
    return <PageSpinner caption="Loading..." />
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <BrowserRouter>
          <div className="main">
            <SideBar />
            {/*<AppBar />*/}
            <div className="content">
              <Switch>
                <PublicRoute path="/login" component={Login} />
                <PublicRoute path="/forgot" component={ForgotPassword} />
                <PublicRoute path="/reset/:sig" component={ResetPassword} />
                <PublicRoute path="/verify/:id" component={VerifyUser} />
                <PrivateRoute path="/register" component={Register} />
                <PrivateRoute exact path="/auth/authorize/:type" component={OAuth2Authorize} />
                <PrivateRoute exact path="/auth/redirect/:type" component={OAuth2Redirect} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/integrations" component={Integrations} />
                <PrivateRoute exact path="/flows" component={IncidentFlows} />
                <PrivateRoute exact path="/flows_new" component={Flows} />
                <PrivateRoute exact path="/flows/:ruleId" component={FlowEditor} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/settings" component={Settings} />
                <PrivateRoute component={NotFoundPage} />
                <Redirect to={isAuthenticated ? '/' : '/login'} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
        <EnvironmentTag />
        <div className="modals" />
      </div>
    </ThemeProvider>
  );
}

export default App;
