import React from 'react';
import Button from '@material-ui/core/Button';

const CancelButton = ({ text='Cancel', type, onClick, color = 'primary' }) => {
  return <Button
    variant='outlined'
    color={color}
    onClick={onClick}
    type={type}
  >
    {text}
  </Button>
};


export default CancelButton;
