import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Row} from "reactstrap";
import Draggable from '../../helpers/Draggable';
import Editable from '../../editors/Editable';
import general from '../../../utils/general';
import './CriteriaEditor.scss';
import $ from 'jquery';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import * as Icons from "../../core/icons";
import { IconButton } from '../../core/buttons';
import { MsgBox } from '../../../components';

export default ({initialCriteria, onChange}) => {
  const [criteria, setCriteria] = useState(general.clone(initialCriteria));
  const [showRawCriteria, setShowRawCriteria] = useState(false);

   const renderGroup = (group, level = 0, path = '') => {
    const allowDelete = level > 0;
    const offset = 30 * level;
    const groupingType = Object.keys(group)[0];
    const children = group[groupingType];
    path = (path ? `${path}_${groupingType}` : groupingType);
    return <Draggable key={path} draggable={allowDelete} index={path} onDrop={onDrop} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragClassName="dragged">
      <Row className="criteria-group" style={{marginLeft: offset}}>
        {level > 0 ? <Icons.DragIndicator className="drag-handle" /> : null}
        <div>
          <Select className="select"
                  variant="outlined"
                  size='small'
                  value={groupingType}
                  onChange={(e) => onCriteriaValueChange(group, '', path, e)}
          >
            <MenuItem value='must'>And</MenuItem>
            <MenuItem value='should'>Or</MenuItem>
          </Select>
        </div>
        { allowDelete && <IconButton iconName='Delete' onClick={() => onDeleteRow(path, true)} tooltip='Delete section' /> }
      </Row>
      {renderChildren(children, level + 1, path)}
      {renderButtons(group, level, path)}
    </Draggable>;
  };

  const renderChildren = (items, level = 0, path = '') => {
    if (Array.isArray(items)) {
      return items.map((item, itemIndex) => renderChildren(item, level, `${path}_${itemIndex}`));
    } else {
      if (items.hasOwnProperty('leftOperand'))
        return renderItem(items, level, path);

      return renderGroup(items, level, path);
    }
  };

  const renderItem = (item, level, path) => {
    const offset = 30 * level;
    return <Draggable key={path} draggable index={path} onDrop={onDrop} onDragStart={onDragStart} onDragEnd={onDragEnd} onDragClassName="dragged">
      <Row className="simple-criteria" style={{marginLeft: offset}}>
        <Icons.DragIndicator className="drag-handle" />
        {renderLeftOperand(item, path)}
        {renderOperator(item, path)}
        {renderRightOperand(item, path)}
        <IconButton iconName='Delete' onClick={() => onDeleteRow(path, false)} tooltip='Delete row' />
      </Row>
    </Draggable>;
  };

  const renderButtons = (item, level, path) => {
    const offset = 30 * level;
    return <Row key={`row_${path}`} className="criteria-buttons" style={{marginLeft: offset + 70}}>
      <div>
        <IconButton iconName='AddBox' onClick={(e) => addSimple(item, path, e)} tooltip='Add item' />
      </div>
      <div>
        <Select className="select"
                variant="outlined"
                size='small'
                value='none'
                onChange={(e) => addGroup(item, path, e)}
        >
          <MenuItem name='none' value='none'>Add Group</MenuItem>)
          <MenuItem name='must' value='must'>And</MenuItem>)
          <MenuItem name='should' value='should'>Or</MenuItem>)
        </Select>
      </div>
    </Row>;
  };

  const renderLeftOperand = (item, path) => {
    return <div className="left-operand">
      <Select className="select"
              variant="outlined"
              size='small'
              value={item.leftOperand}
              onChange={(e) => onCriteriaValueChange(item, 'leftOperand', path, e)}
      >
        {_fields.map((f, index) => <MenuItem key={'f_field_' + index} value={f.value}>{f.label}</MenuItem>)}
      </Select>
    </div>;
  };

  const renderOperator = (item, path) => {
    return <div className="operator">
      <Select className="select"
              variant="outlined"
              size='small'
              value={item.operator}
              onChange={(e) => onCriteriaValueChange(item, 'operator', path, e)}
      >
        {_operators.map((o, index) => <MenuItem key={'o_operator_' + index} value={o.value} >{o.label}</MenuItem>)}
      </Select>
    </div>;
  };

  const renderRightOperand = (item, path) => {
    const operatorDefinition = _operators.find(o => o.value === item.operator);
    if (!operatorDefinition || !operatorDefinition.noRightOperand) {
      return <TextField value={item.rightOperand}
                        variant="outlined"
                        size="small"
                        name="description"
                        onChange={ (e) => onCriteriaValueChange(item, 'rightOperand', path, e)}
      />;
    }

    return null;
  };

  const onCriteriaValueChange = (item, fieldName, path, event) => {
    const clonedCriteria = general.clone(criteria);
    if (!fieldName) {
      const criteriaItem = getParent(clonedCriteria, path);
      const pathParts = path.split('_');
      const groupingType = pathParts[pathParts.length-1];
      criteriaItem[event.target.value] = criteriaItem[groupingType];
      delete criteriaItem[groupingType];
    }
    else {
      const criteriaItem = getItemByPath(clonedCriteria, path);
      criteriaItem[fieldName] = event.target.value;
    }
    updateCriteria(clonedCriteria);
  };

  const getParent = (criteriaItem, path) => {
    let split = path.split('_');
    let parent = criteriaItem;
    for (let i=0; i<split.length-1; i++) {
      parent = parent[split[i]];
    }

    return parent;
  };

  const getItemByPath = (criteriaItem, path) => {
    let split = path.split('_');
    let item = criteriaItem;
    for (let i=0; i<split.length; i++) {
      item = item[split[i]];
    }
    return item;
  };

  const onDrop = (srcPath, destPath) => {
    const clonedCriteria = general.clone(criteria);
    const draggedItemInfo = getItemInfo(clonedCriteria, srcPath);
    const dropItemInfo = getItemInfo(clonedCriteria, destPath);

    if (draggedItemInfo.collectionPath === dropItemInfo.collectionPath) {
      general.reorder(dropItemInfo.collection, draggedItemInfo.index, dropItemInfo.index);
    } else {
      const item = draggedItemInfo.collection.splice(draggedItemInfo.index, 1)[0];
      dropItemInfo.collection.splice(dropItemInfo.index, 0, item);
    }

    const firstKey = Object.keys(clonedCriteria)[0];
    clonedCriteria[firstKey] = removeEmptyArrays(clonedCriteria[firstKey]);

    updateCriteria(clonedCriteria);
  };

  const onDragStart = (evt) => {
    $('.save-changes-bar').hide();
  };

  const onDragEnd = (evt) => {
    $('.save-changes-bar').show();
  };

  const getItemInfo = (criteria, path) =>{
    let split = path.split('_');
    let index = split.pop();
    let isGrouping = false;
    let collectionPath = split.join('_');
    let object = getItemByPath(criteria, path);
    if (!isNumeric(index)) {
      isGrouping = true;
      object = {[index]: object};
      if (split.length) {
        index = split.pop();
        collectionPath = split.join('_');
      }
    }
    let collection = getItemByPath(criteria, collectionPath || index);
    return {
      object,
      collection,
      count: collection.length,
      index,
      path,
      collectionPath,
      isGrouping
    };
  };

  const removeEmptyArrays = (arr) => {
    const result = [];
    let item, firstKey;
    for (let i=0; i<arr.length; i++) {
      item = arr[i];
      firstKey = Object.keys(item)[0];
      if (['must', 'should'].includes(firstKey)) {
        if (Array.isArray(item[firstKey]) && item[firstKey].length) {
          const tmpArray = removeEmptyArrays(item[firstKey]);
          if (tmpArray.length) {
            result.push({[firstKey]: tmpArray});
          }
        }
      } else {
        result.push(item);
      }
    }

    return result;
  };

  const isNumeric = (value) => {
    return !Array.isArray(value) && value - parseFloat(value) + 1 >= 0;
  };

  const onDeleteRow = (path, isGroup) => {
    const clonedCriteria = general.clone(criteria);
    let split = path.split('_');
    if (isGroup) {
      split.pop();
      path = split.join('_');
    }
    let parent = getParent(clonedCriteria, path);
    let lastSegment = split[split.length - 1];
    parent.splice(lastSegment, 1);

    const firstKey = Object.keys(clonedCriteria)[0];
    clonedCriteria[firstKey] = removeEmptyArrays(clonedCriteria[firstKey]);

    updateCriteria(clonedCriteria);
  };

  const addSimple = (item, path, e) => {
    e.stopPropagation();
    const clonedCriteria = general.clone(criteria);
    const criteriaItem = getItemByPath(clonedCriteria, path);
    criteriaItem.push({leftOperand: _fields[0].value, operator: _operators[0].value, rightOperand: ''});
    updateCriteria(clonedCriteria);
  };

  const addGroup = (item, path, e) => {
    e.stopPropagation();
    const selectedValue = e.target.value;
    if (selectedValue !== 'none') {
      const clonedCriteria = general.clone(criteria);
      const criteriaItem = getItemByPath(clonedCriteria, path);
      criteriaItem.push({[selectedValue]: [{leftOperand: _fields[0].value, operator: _operators[0].value, rightOperand: ''}]});
      updateCriteria(clonedCriteria);
    }
  };

  const updateCriteria = (updatedCriteria) => {
    setCriteria(updatedCriteria);
    if (onChange) {
      onChange(updatedCriteria);
    }
  };

  return <div className='criteria-editor'>
      <div className='show-raw' >
        <a onClick={(e) => setShowRawCriteria(true)}>Show raw criteria</a>
      </div>
      {renderChildren(criteria)}
    <MsgBox
      open={showRawCriteria}
      onClose={(e) => setShowRawCriteria(false)}
      title='Raw Criteria'
    >
      <pre>{JSON.stringify(criteria, undefined, 2)}</pre>
    </MsgBox>
  </div>;
}

// TODO: get fields and operators from server
const _fields = [
  { label: 'Log message', value: '{log.message}'},
  { label: 'Log level', value: '{log.level}'},
  { label: '# of times rule was triggered', value: '{rule.triggered}'}
];

const _operators = [
  { label: 'Equals', value: 'Equals'},
  { label: 'Not equals', value: 'NotEquals'},
  { label: 'Contains', value: 'Contains'},
  { label: 'Not contains', value: 'NotContains'},
  { label: 'Is empty', value: 'IsEmpty', noRightOperand: true},
  { label: 'Is not empty', value: 'IsNotEmpty', noRightOperand: true},
  { label: 'Greater than', value: 'GreaterThan'},
  { label: 'Greater than or equal', value: 'GreaterThanEqual'},
  { label: 'Less than', value: 'LessThan'},
  { label: 'Less than or equal', value: 'LessThanEqual'},
  { label: 'In', value: 'In'},
  { label: 'Not in', value: 'NotIn'},
  { label: 'Starts with', value: 'StartsWith'},
  { label: 'And', value: 'And'},
  { label: 'Or', value: 'Or'}
];
