import React from "react";
import styled from 'styled-components';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import * as Icons from "../../core/icons";


const StyledIconButton = styled(IconButton)`
  &:focus {
    outline: none;
  }
`;

export default ({ iconName, icon, onClick, tooltip, className }) => {
  const Icon = icon || Icons[iconName];
  const btn = <StyledIconButton className={className} onClick={onClick} >
    <Icon />
  </StyledIconButton>;

  if (tooltip) {
    return <Tooltip title={tooltip}>
      {btn}
    </Tooltip>;
  }

  return btn;
};



