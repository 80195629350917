import React from 'react';
import BaseAction from './BaseAction';

class SendEmailAction extends BaseAction {

  get EmailPriorities() {
    return [
      {value: 'normal', label: 'Normal'},
      {value: 'high', label: 'High'},
      {value: 'low', label: 'Low'}
    ];
  }

  renderAction(action) {
    return <div>
      {this.renderInput(action, 'Subject', 'subject')}
      {this.renderSelect(action, 'Priority', 'priority', this.EmailPriorities, 'normal')}
      {this.renderInput(action, 'To', 'to')}
      {this.renderInput(action, 'CC', 'cc')}
      {this.renderInput(action, 'BCC', 'bcc')}
       {this.renderInput(action, 'Attachments', 'attachments')}
      {this.renderTextarea(action, 'Body', 'html')}
    </div>;
  }
}

export default SendEmailAction;
