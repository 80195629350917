/* eslint-disable no-param-reassign */
import axios from 'axios';
import { TranslationHelper } from './i18n';
import store from '../store';
import { userLogout } from '../actions/authActions';
import { getCSRFToken } from '../api/auth';

class RetryHelper {
  static async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  static async retry(config) {
    if (!config.retries)
      config.retries = 0;
    config.retries++;
    if (config.retries <= 3) {
      console.log(`Retrying request... retry #${config.retries + 1}. waiting ${config.retries * 1000}ms`);
      await RetryHelper.sleep(config.retries * 1000);
      return axios.request(config);
    }
    return { success: false, statusCode: 500, error: 'Could not reach the server. Make sure you have an internet connection and try again.'};
  }
}

class AppGuardErrorHandler {

  static get IsEnabled() {
    return !!window.__AppGuard__;
  }

  static isSupportMode(error) {
    const { response } = error;
    return !!(response && response.data && response.data.errorId);
  }

  static validateError(error) {
    const { response, message } = error;
    if (response) {
      const { data, status } = response;
      if (status === 500 && data.error === 'INTERNAL_SERVER_ERROR' && data.errorId)
        return true;
      if (status === 403 && !data.error && data !== 'Forbidden' && message === 'Request failed with status code 403')
        return true;
    } else if (window.navigator.onLine) {
      // There is no response, means that it was blocked before reaching the server
      return true;
    }
  }

  static notifyIfNeeded(error) {
    if (!AppGuardErrorHandler.IsEnabled)
      return;

    window.__AppGuard__.handleAxiosError(error, AppGuardErrorHandler.validateError, AppGuardErrorHandler.isSupportMode);
  }
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        config.headers['X-Strategy'] = 'cookie';
        config.headers['X-Referer'] = window.location.href;
        return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (axios.isCancel(error))
      return error;
    const { config, response, message } = error;
    AppGuardErrorHandler.notifyIfNeeded(error);
    let statusCode = 500;
    // Do something with response error
    if (response) {
      const { data, status } = response;
      statusCode = data.statusCode || status || 500;
      if([401, 403].includes(status) && data === "Unauthorized") {
        store.dispatch(userLogout());
      }
      if (status === 403 && data.error === 'INVALID_CSRF_TOKEN') {
        console.log('CSRF token is invalid. Re-fetching CSRF token');
        return getCSRFToken().then(() => {
          return axios.request(config);
        });
      }
      if (data.error) {
        data.error = TranslationHelper.getError(data.error);
        return data;
      }
    } else {
      return RetryHelper.retry(config);
    }
    // There is no response (Network error)
    // We got a network error and there is no internet connection
    const errorMessage = !window.navigator.onLine ? 'No internet connection' : message;
    return { success: false, statusCode, error: errorMessage };
  }
);
