/*

[
  {
    "provider": "Slack",
    "type": "getUserByEmail",
    "parameters": "dmosdev@gmail.com"
  },
  {
    "provider": "System",
    "type": "setVariable",
    "variableName": "user",
    "value": "{actions[0].result}"
  },
  {
    "provider": "Slack",
    "type": "notify",
    "data": {
      "channel": "C0116DX2H96",
      "attachments": [
        {
          "fallback": "Warning received from {log.environment} environment (occurred {meta.timesReceived} times)",
          "pretext": "Warning received from {log.environment} environment (occurred {meta.timesReceived} times)",
          "color": "#EFA808",
          "author_name": "{log.sourceName} {log.serviceName} - {log.notifyTo}",
          "title": "Warning",
          "text": "{log.message}",
          "footer": "{log.environment} Warning <@{user.id}>",
          "footer_icon": "{user.profile.image_24}",
          "ts": "{log.timestamp}"
        }
      ]
    }
  },
  {
    "provider": "System",
    "type": "sendEmail",
    "subject": "{log.sourceName} {log.serviceName} Warning from {log.environment}",
    "html": "Hi {log.notifyTo},<br />We've got a warning: {log.message}<br /><p>(occurred {meta.timesReceived} times)</p>",
    "to": "{log.notifyTo},nimrod@deepcoding.ai",
    "cc": "ori.roniger@deepcoding.ai"
  }
]

 // Error
[
  {
    "provider": "Slack",
    "type": "getUserByEmail",
    "parameters": "dmosdev@gmail.com"
  },
  {
    "provider": "System",
    "type": "setVariable",
    "variableName": "user",
    "value": "{actions[0].result}"
  },
  {
    "provider": "Slack",
    "type": "notify",
    "data": {
      "channel": "C0116DX2H96",
      "attachments": [
        {
          "fallback": "Error received from {log.environment} environment",
          "pretext": "Error received from {log.environment} environment",
          "color": "#BA2B00",
          "author_name": "{log.sourceName} {log.serviceName} - {log.notifyTo}",
          "title": "Error",
          "text": "{log.message}",
          "footer": "{log.environment} Warning <@{user.id}>",
          "footer_icon": "{user.profile.image_24}",
          "ts": "{log.timestamp}"
        }
      ]
    }
  },
  {
    "provider": "System",
    "type": "sendEmail",
    "subject": "{log.sourceName} {log.serviceName} error from {log.environment}",
    "html": "We received the following error in {log.environment}: {log.message}<br />URL: ({log.method}) {log.url}<br /><p>Transaction ID: {log.transactionId}</p><p>IP: {log.ip}<br />Payload: {log.payload}</p>Stacktrace:<pre>{log.stack}</pre><p>Client: {log.client}<br />User: {log.userEmail} (id: {log.userId} | role: {log.role})</p><p><a href='https://console.cloud.google.com/logs/viewer?minLogLevel=0&expandAll=false&customFacets=true&limitCustomFacetWidth=true&interval=PT1H&folder=&organizationId=&dateRangeEnd=2020-07-26T07:40:28.990Z&dateRangeStart=2020-07-26T06:40:28.990Z&advancedFilter=resource.type%20%3D%20%22cloud_run_revision%22%0AjsonPayload.transactionId%20%3D%20%22{log.transactionId}%22%0A%20severity%3E%3DDEFAULT'>View logs</a></p><small><em>Occurred {meta.timesReceived} times, {meta.timesReceivedSinceLastExecution} times since last notification</em></small>",
    "to": "nimrod@deepcoding.ai",
    "cc": "ori.roniger@deepcoding.ai"
  }
]


 */



class IncidentFlow {

    static create(flow) {
        if (flow)
            return Object.assign(new IncidentFlow(), flow);

        return new IncidentFlow();
    }

    constructor() {
        this.name = '';
        this.order = 0;
        this.disabled = true;
        this.criteria = {
            "should": [
                {
                    "leftOperand": "{log.level}",
                    "operator": "Equals",
                    "rightOperand": "error"
                }
            ]
        };
        this.actions = [
          {
            "provider": "Slack",
            "type": "getUserByEmail",
            "parameters": "dmosdev@gmail.com"
          },
          {
            "provider": "System",
            "type": "setVariable",
            "variableName": "user",
            "value": "{actions[0].result}"
          },
          {
            "provider": "Slack",
            "type": "notify",
            "channel": "C0116DX2H96",
            "data": {
              "attachments": [
                {
                  "fallback": "Error received from {log.environment} environment",
                  "pretext": "Error received from {log.environment} environment",
                  "color": "#BA2B00",
                  "author_name": "{log.sourceName} {log.serviceName} - {log.notifyTo}",
                  "title": "Error",
                  "text": "{log.message}",
                  "footer": "{log.environment} Warning <@{user.id}>",
                  "footer_icon": "{user.profile.image_24}",
                  "ts": "{log.timestamp}"
                }
              ]
            }
          },
          {
            "provider": "System",
            "type": "sendEmail",
            "subject": "{log.sourceName} {log.serviceName} error from {log.environment}",
            "html": "We received the following error in {log.environment}: {log.message}<br />URL: ({log.method}) {log.url}<br /><p>Transaction ID: {log.transactionId}</p><p>IP: {log.ip}<br />Payload: {log.payload}</p>Stacktrace:<pre>{log.stack}</pre><p>Client: {log.client}<br />User: {log.userEmail} (id: {log.userId} | role: {log.role})</p><p><a href='https://console.cloud.google.com/logs/viewer?minLogLevel=0&expandAll=false&customFacets=true&limitCustomFacetWidth=true&interval=PT1H&folder=&organizationId=&dateRangeEnd=2020-07-26T07:40:28.990Z&dateRangeStart=2020-07-26T06:40:28.990Z&advancedFilter=resource.type%20%3D%20%22cloud_run_revision%22%0AjsonPayload.transactionId%20%3D%20%22{log.transactionId}%22%0A%20severity%3E%3DDEFAULT'>View logs</a></p><small><em>Occurred {meta.timesReceived} times, {meta.timesReceivedSinceLastExecution} times since last notification</em></small>",
            "to": "ori@appguard.app",
            "cc": "david@appguard.app"
          }
        ];
    }

    validate() {
        return !!this.name && !isNaN(this.order) && Array.isArray(this.actions) && this.criteria;
    }

}

export default IncidentFlow;
