import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import '../assets/styles/components/_environment-tag.scss';

const EnvironmentTag = () => {

  const [animate, setAnimate] = useState(false);

  const { user } = useSelector(state => state.auth);

  setTimeout(() => setAnimate(true), 1000);

  const environmentName = (process.env.REACT_APP_CLUSTER_ENV || process.env.NODE_ENV)

  const isGuest = user && user.role === 'guest';
  if(process.env.NODE_ENV !== 'production' || isGuest){
    const text = isGuest ? "Free version" : environmentName.toUpperCase();
    return <i className={"environment environment--mode-" + environmentName + (animate ? ' environment--load' : '')}>{text}</i>;
  }
  return null;
};

export default EnvironmentTag;
