import React from 'react';
import PropTypes from 'prop-types';
import { MdSearch, MdClear } from 'react-icons/md';
import 'jquery-highlight';
import $ from 'jquery';
import '../assets/styles/components/_search-input.scss';

export class SearchBar extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.setFocus = this.setFocus.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.state = {
      showClearBtn: false
    };
}

  componentDidMount() {
    if (this.props.focus)
      this.setFocus();
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.clearSearch)
      this.handleClear();
    if(nextProps.reSearch)
      this.handleChange();
    if(nextProps.focus)
      this.setFocus();
  }

  setFocus(event) {
    this.searchInput.focus();
  }

  handleClear(event) {
    this.searchInput.value="";
    this.handleChange();
    this.setFocus(event);
  }

  onEscape(event) {
    if (event.keyCode === 27 && event.target.value !== "") {
      event.stopPropagation();
      event.preventDefault();
      event.target.value = "";
      this.handleChange(event);
    }
  }

  handleChange(event) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }

    let filterText = this.searchInput.value.toLocaleLowerCase();
    $(this.wrapper).toggleClass("search-results", this.searchInput.value !== "");
    if(this.props.onSearchChange) {
      this.props.onSearchChange(filterText);
    }

    this.searchInput.value !== "" ? this.setState({showClearBtn:true}) : this.setState({showClearBtn:false});

    this.highlightTableSearch(filterText);
  }

  highlightTableSearch(filterText){
    if(this.props.containerId){
      let $container = $("#" + this.props.containerId);
      if ($container.unhighlight)
        $container.unhighlight();
      $container.find(".no-results-message").remove();
      let foundResult = false;
      $('.search-line', $container).each(function(index, tr){
        let $tr = $(tr);
        let match = $tr.text().toLocaleLowerCase().indexOf(filterText) > -1;
        $tr.toggle(match);
        if(match){
          $tr.highlight(filterText);
          foundResult = true;
        }
      });
      if(!foundResult){
        $container.append("<div class=\"no-results-message\">No matches found</div>");
      }
    }
  }

  render() {
      return (
        <div ref={(wrapper) => this.wrapper = wrapper} className="cr-search-form form-inline">
          <MdSearch
            onClick={this.setFocus}
            size="20"
            className="cr-search-form__icon-search text-secondary"
          />
          <input className="form-control cr-search-form__input" ref={(input) => this.searchInput = input} onKeyDown={this.onEscape.bind(this)} onChange={this.handleChange.bind(this)} value={this.props.filterText} type="text" placeholder="חיפוש" />
          <MdClear
              className="cr-search-form__icon-clear text-secondary"
              style={{visibility: this.state.showClearBtn ? "visible": "hidden"}}
              onClick={this.handleClear}
              size="20"
            />
        </div>
      );
  }
}

SearchBar.propTypes = {
    filterText: PropTypes.string,
    onSearchChange: PropTypes.func,
    containerId: PropTypes.string,
    focus: PropTypes.bool,
    clearSearch: PropTypes.bool,
    reSearch: PropTypes.bool,
    addCssClass: PropTypes.string
};

export default SearchBar;
