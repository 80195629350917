
// theme object: https://material-ui.com/customization/default-theme/
export const darkTheme = {
  palette: {
    type: 'dark'
  },
  zIndex: {
    drawer: 1200,
    appBar: 1100
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
      }
    }
  }
};

export const lightTheme = {
  palette: {
    type: 'light',
    primary: {
      main: '#232944'
    },
  },
  zIndex: {
    drawer: 1200,
    appBar: 1100
  }
};



