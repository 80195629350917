import React, {useEffect, useState} from 'react';
import './Action.scss';

export default ({ action }) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [logs, setLogs] = useState([]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     setIsLoading(true);
  //     if (mode === 'LATEST') {
  //       AnalyticsAPI.getLatestLogs(startDate, endDate).then(res => {
  //         setLogs(res.data || []);
  //       }).finally(() => {
  //         setIsLoading(false);
  //       });
  //     } else  {
  //       AnalyticsAPI.getMostFrequentLogs(startDate, endDate).then(res => {
  //         setLogs(res.data || []);
  //       }).finally(() => {
  //         setIsLoading(false);
  //       });
  //     }
  //   }
  // }, [startDate, endDate]);

  // if (isLoading)
  //   return <div className='logs-table'>
  //     <CircularProgress color='primary' size={30} />
  //   </div>;

  const editAction = () => {

  };

  return (
    <div className="action" onClick={editAction}>
      {getActionLabel(action)}
    </div>
  );
};

const getActionLabel = (action) => {
  switch (action.provider) {
    case 'Slack': return getSlackActionLabel(action.type);
    case 'Jira': return getJiraActionLabel(action.type);
    case 'Telegram': return getTelegramActionLabel(action.type);
    case 'System': return getSystemActionLabel(action.type);
  }
};

const getSlackActionLabel = (actionType) => {
  switch (actionType) {
    case 'getUserByEmail': return 'Get Slack user by email';
    case 'notify': return 'Send Slack notification';
  }
};

const getJiraActionLabel = (actionType) => {
  switch (actionType) {
    case 'createIssue': return 'Create Jira Issue';
  }
};

const getTelegramActionLabel = (actionType) => {
  switch (actionType) {
    case 'notify': return 'Send Telegram notification';
  }
};

const getSystemActionLabel = (actionType) => {
  switch (actionType) {
    case 'sendEmail': return 'Send email';
    case 'setVariable': return 'Set Variable';
  }
};

