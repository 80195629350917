import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import './TimeRangeSelect.scss';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RANGE_OPTIONS = [
  { text: 'Last day', value: 'LAST_DAY'},
  { text: 'Last 7 days', value: 'LAST_7_DAYS'},
  { text: 'Last 30 days', value: 'LAST_30_DAYS'},
  { text: 'Last 3 months', value: 'LAST_3_MONTHS'},
  { text: 'Last 6 months', value: 'LAST_6_MONTHS'},
  { text: 'Last year', value: 'LAST_YEAR'}
];

const TimeRangeSelect = ({ onChange }) => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState(RANGE_OPTIONS[2].value);

  useEffect(() => {
    onRangeChange({ target: { value: selectedValue }});
  }, []);

  const onRangeChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    if (onChange) {
      let endDate = moment().endOf('day');
      let startDate = moment().endOf('day');
      switch (value) {
        default:
        case 'LAST_DAY': startDate.add(-1, 'days');
          break;
        case 'LAST_7_DAYS': startDate.add(-7, 'days');
          break;
        case 'LAST_30_DAYS': startDate.add(-30, 'days');
          break;
        case 'LAST_3_MONTHS': startDate.add(-3, 'months');
          break;
        case 'LAST_6_MONTHS': startDate.add(-6, 'months');
          break;
        case 'LAST_YEAR': startDate.add(-1, 'years');
          break;
      }

      startDate = startDate.format('yyyy-MM-DD');
      endDate = endDate.format('yyyy-MM-DD');

      onChange({startDate, endDate});
    }
  };

  return (
    <Select className="time-range-select"
      variant="outlined"
      labelId="date-range-outlined-label"
      id="date-range-outlined"
      value={selectedValue}
      onChange={onRangeChange}
    >
      {RANGE_OPTIONS.map((option, index) =>
        <MenuItem key={index} value={option.value}>{option.text}</MenuItem>
      )}
    </Select>
  );
};

export default TimeRangeSelect;
