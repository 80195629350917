import React from 'react';
import PropTypes from 'prop-types';
import general from '../../utils/general';
import '../../assets/styles/components/editors.scss';

class Editable extends React.Component {

  static propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.any,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    className: PropTypes.string,
    label: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      isEditing: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {...state};
    newState.value = props.value;
    return newState;
  }

  renderInputElement() {
    let value = this.state.value || undefined;
    switch (this.props.type) {
      case 'textarea':
        try {
          if (typeof value === 'object')
            value = JSON.stringify(value, undefined, 2);
        }
        catch (e) {
          // Do nothing
        }
        return <textarea
        className={'form-control'}
        name={this.props.name}
        rows={this.props.rows || 4}
        cols={this.props.cols || 50}
        onChange={this.props.onChange}
        onBlur={(evt) => this.onBlur(evt)}
        onKeyDown={(evt) => this.onKeyDown(evt)}
        value={value}
        />;
      default:
        return <input
            className={"form-control"}
            type={this.props.type || "text"}
            name={this.props.name}
            value={value}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder} />;
    }
  }

  onBlur(evt) {
    let value = evt.target.value;
    const fieldName = evt.target.name;
    // try handling JSON strings
    if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        // Do nothing
      }
    }
    this.props.onChange({target: {name: fieldName, value: value}});
  }

  onKeyDown(evt) {
    if (general.enableEnhancedEditingSupportForTextarea(evt)) {
      this.props.onChange(evt);
    }
  }

  get isEditable() {
    return !this.props.disabled && !this.props.readonly && this.props.onChange;
  }

  getContainerClassName() {
    const classNames = ['editable-field'];
    if (this.props.className)
      classNames.push(this.props.className);

    if (this.isEditable)
      classNames.push('editable');

    return classNames.join(' ');
  }

  render() {
    const value = this.state.value || undefined,
      input = this.isEditable ? this.renderInputElement() : value;

    return <div className={this.getContainerClassName()}>
      <em className="label-cnt">{this.props.label}</em>
      <div className="value-cnt">{input}</div>
    </div>;
  }
}

export default Editable;
