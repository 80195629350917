import PropTypes from "prop-types";
import React from "react";

class StatusFormatter extends React.Component {
  render() {
    return <div className="state-formatter">
      {!this.props.value || !this.props.value.func ? 
        <div className="state-formatter--is-active">
          Ready
        </div> :
        <div className="state-formatter--is-pending">
          <em>Pending...</em>
          <button type="button" className="btn btn-outline-primary btn-sm btn-resend" onClick={this.props.value.func} key="resend-btn">Resend</button>
        </div>
      }
    </div>;
  }
}
StatusFormatter.propTypes = {
  value: PropTypes.object
};

export default StatusFormatter;
