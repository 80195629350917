import axios from "axios";

// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
  return axios.get(`/token`);
};

export const getCSRFToken = async () => {
  return axios.get('/csrf-token');
};
// verify refresh token to generate new access token if refresh token is present
export const userReset = async (email, password, verification) => {
  return axios.post(`/reset`, { email, password, verification });
};

// user login API to validate the credential
export const userRegisterService = async (email, password, organizationName, timezone) => {
  return axios.post(`/register`, { email, password, organizationName, timezone });
};

// user login API to validate the credential
export const userLoginService = async (email, password) => {
  return axios.post(`/login`, { email, password });
};

// user login API to validate the credential
export const userForgotPasswordService = async (email) => {
  return axios.post(`/forgot`, { email });
};

export const validateSignature = async (verification) => {
  return axios.get(`/validate?verification=${verification}`);
};

// verify email address
export const verifyEmail = async (verification) => {
  return axios.post(`/verify`, {verification});
};

// manage user logout
export const userLogoutService = async () => {
  return axios.post(`/logout`);
};

// get reset link
export const getResetLink = async (email) => {
  return axios.post(`/resetlink`, { email });
};

export const switchToOrganizationService = async (id) => {
  return axios.get(`/organization/${id}/switch`);
};
