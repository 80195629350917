import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import cx from 'classnames';
import './Dialog.scss';

export default ({ open, children, header, footer, onClose, className, modal=true }) => {
  return <Dialog
    open={open}
    onClose={onClose}
    className={cx('dialog', className)}
    disableBackdropClick={modal}
    disableEnforceFocus
  >
    <DialogTitle className="dialog-title">{header}</DialogTitle>
    <DialogContent className="dialog-content">
      {children}
    </DialogContent>
    {footer && <DialogActions className="dialog-footer">
      {footer}
    </DialogActions>}
  </Dialog>;
};

