import React from 'react';
import BaseAction from './BaseAction';

class SetVariableAction extends BaseAction {

  renderAction(action) {
    return <div>
      {this.renderInput(action, 'Variable name', 'variableName')}
      {this.renderInput(action, 'Default value', 'defaultValue')}
      {this.renderInput(action, 'Value', 'value')}
    </div>;
  }
}

export default SetVariableAction;