import React from 'react';
import Button from '@material-ui/core/Button';

export default ({ children, type, onClick, disabled, color = 'primary', ...rest }) => {
  return <Button
    variant="contained"
    color={color}
    disabled={disabled}
    onClick={onClick}
    type={type}
    {...rest}
  >
    {children}
  </Button>
};
