import React from 'react';
import './Panel.scss';
import CircularProgress from '@material-ui/core/CircularProgress';


const Panel = ({ title, isLoading, icon: ICON, children, titleType }) => {
  if (isLoading)
    return <div className='panel'>
        <CircularProgress color='primary' size={30} />
    </div>;

  return <div className='panel'>
    {titleType === 'top' ? <div className="title">{title}</div> : null}
    <div className="panel-container">
      {ICON ? <ICON /> : null}
      {children}
    </div>
    {titleType !== 'top' ? <p>{title}</p> : null}
  </div>;
};


export default Panel;
