import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { registrationAsync } from '../../asyncActions/authAsyncActions';
import '../../assets/styles/pages/login.scss';
import { Buttons } from '../core';
import queryString from '../../utils/queryString';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icons } from '../../components/core';
import { useHistory } from 'react-router-dom';

function Register() {
  const searchInput = useRef(null);
  useEffect(()=> {
    // current property is referred to input element
    if (searchInput && searchInput.current)
      searchInput.current.focus();
   },[]);
  const history = useHistory();
  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { registrationLoading, registrationError, user } = authObj;
  const [formError, setFormError] = useState('');
  // This should be replaced in the future with isAuthenticated from authObj
  const [originalUserName, setOriginalUserName] = useState(user.email);

  const initialUsername = queryString.getParameter('email') || '';

  const username = useFormInput(initialUsername);
  const password = useFormInput('');
  const repeatpassword = useFormInput('');
  const organization = useFormInput('');
  const agree = useFormCheckbox(false);

  if (user.role !== 'superuser') {
    history.push('/');
    return <></>;
  }

  // After registration test if was successful
  const registrationSuccess = !registrationLoading && !registrationError && originalUserName !== user.email;

  const validateForm = () => {
    if (!password.value || !username.value || !organization.value)
      return setFormError('Please fill in all mandatory fields');
    if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(username.value))
      return setFormError('Email is not valid');
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&])/.test(password.value))
      return setFormError('Password must contain 8-16 characters, contain at least one number, one special character, one lowercase and one uppercase');
    if (password.value !== repeatpassword.value)
      return setFormError('Passwords do not match');
    if (!agree.value)
      return setFormError('You must agree to the Privacy Policy and the Terms and Conditions');
    setFormError('');
    return true;
  };

  // handle button click of login form
  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (validateForm())
      dispatch(registrationAsync(username.value, password.value, organization.value, Intl.DateTimeFormat().resolvedOptions().timeZone));
  };

  return (
    <div className="login-form">
      <form>
        <div className="branding">
          <h1><span>APP</span>GUARD</h1>
          <h2>Customer Registration</h2>
        </div>
        {!registrationSuccess ?
        <div className="form">
          <div>
            <div><em>Organization Name</em></div>
            <input type="text" name="organization" {...organization} autoComplete="new-password" ref={searchInput} />
          </div>
          <div>
            <div><em>Email</em></div>
            <input type="text" name="username" {...username} autoComplete="new-username" />
          </div>
          <div>
            <div><em>Password</em></div>
            <input type="password" name="password" {...password} autoComplete="new-password" />
          </div>
          <div>
            <div><em>Repeat Password</em></div>
            <input type="password" name="repeatpassword" {...repeatpassword} autoComplete="new-password" />
          </div>
          <div className="checkbox">
            <label><input type="checkbox" name="agree" {...agree} />By clicking submit you are agreeing to the <a href="https://www.appguard.app/privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a> and the <a href="https://www.appguard.app/terms" rel="noopener noreferrer" target="_blank">Terms and Conditions</a></label>
          </div>
          <Buttons.PrimaryButton type="submit" disabled={registrationLoading} onClick={handleSubmit}>Register{registrationLoading ? <CircularProgress size="18" /> : null}</Buttons.PrimaryButton>
          <div className="links"><NavLink to="/login">Login to your account</NavLink></div>
          <div className="error">{(formError || registrationError) ? <><Icons.Error /> {formError || registrationError} /></> : null}</div>
        </div> :
        <div className="form">
          <div>
            <div>Registration succeeded.</div>
            <NavLink to="/">Continue to your account</NavLink>
          </div>
        </div>
        }
      </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};

// custom hook to manage the form input
const useFormCheckbox = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(!value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Register;
