import React, {useEffect, useState} from 'react';
import { AnalyticsAPI } from "../../../api";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import './Logs.scss';

const Logs = ({ mode = 'LATEST', startDate, endDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (startDate && endDate) {
      setIsLoading(true);
      if (mode === 'LATEST') {
        AnalyticsAPI.getLatestLogs(startDate, endDate).then(res => {
          setLogs(res.data || []);
        }).finally(() => {
          setIsLoading(false);
        });
      } else  {
        AnalyticsAPI.getMostFrequentLogs(startDate, endDate).then(res => {
          setLogs(res.data || []);
        }).finally(() => {
          setIsLoading(false);
        });
      }
    }
  }, [startDate, endDate]);

  if (isLoading)
    return <div className='logs-table'>
      <CircularProgress color='primary' size={30} />
    </div>;

  return (
    <div className="logs-table">
      <table>
        <thead>
          <tr>
            <th style={{ width: 150 }}>Last Received</th>
            <th>Log Message</th>
            <th style={{ width: 150 }}>Times Received</th>
            <th style={{ width: 100 }}>Ticket</th>
          </tr>
        </thead>
        <tbody>
        {logs.map((row, index) => <tr key={`${mode}_tr_${index}`}>
          <td>{moment(row.lastReceived).format('DD/MM/YYYY HH:mm:ss')}</td>
          <td className="message-cell">{row.logMessage}</td>
          <td>{row.timesReceived}</td>
          <td>{row.jira_ticket_id}</td>
        </tr>)}
        </tbody>
      </table>
    </div>
  );
};

export default Logs;
