export const VERIFY_TOKEN_STARTED = 'VERIFY_TOKEN_STARTED';
export const VERIFY_TOKEN_END = 'VERIFY_TOKEN_END';

export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const REGISTRATION_STARTED = 'REGISTRATION_STARTED';
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE';

export const VERIFY_REGISTRATION_SUCCESS = 'VERIFY_REGISTRATION_SUCCESS';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
