import React from 'react';
import PropTypes from 'prop-types';
import general from '../../../utils/general';
import '../../../assets/styles/components/editors.scss';

class BaseAction extends React.Component {

  static propTypes = {
    action: PropTypes.object.isRequired,
    onChange: PropTypes.func
  };

  state = {
    action: null,
    additionalData: null
  };

  componentDidMount() {
    this.getAdditionalData();
  }

  getAdditionalData() {
  }

  static getDerivedStateFromProps(nextProps, nextContext) {
    return {
      action: nextProps.action
    }
  }

  renderInput(action, label, fieldName) {
    return <>
      <em>{label}:</em>
      <input className="form-control" type="text" name={fieldName} value={action[fieldName]} onChange={(evt) => this.onChange(evt)} />
    </>;
  }

  renderTextarea(action, label, fieldName) {
    let value = action[fieldName] || '';
    try {
      if (value && typeof value === 'object')
        value = JSON.stringify(value, undefined, 2);
    }
    catch (e) {
      // Do nothing
    }
    return <>
      <em>{label}:</em>
      <textarea className="form-control" name={fieldName} value={value} onBlur={(evt) => this.onBlur(evt)} onKeyDown={(evt) => this.onKeyDown(evt)} onChange={(evt) => this.onChange(evt)} />
    </>;
  }

  renderSelect(action, label, fieldName, values, defaultValue) {
    const value = action[fieldName] || defaultValue;
     return <>
       <em>{label}:</em>
       <select className="form-control" name={fieldName} value={value} onChange={(evt) => this.onChange(evt)}>
         {values.map((v,index) => <option key={`${fieldName}_${index}`} value={v.value}>{v.label || v.value}</option>)}
      </select>
     </>;
  }

  render() {
    return <div className='actions-container'>
      {this.renderAction(this.state.action)}
    </div>;
  }

  updateAction(fieldName, value, onAfterUpdateState) {
    const action = general.clone(this.state.action);
    action[fieldName] = value;
    this.setState({ action }, onAfterUpdateState);
    if (this.props.onChange)
      this.props.onChange(action);
  }

  onBlur(evt) {
    let value = evt.target.value;
    const fieldName = evt.target.name;
    // try handling JSON strings
    if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
      try {
        value = JSON.parse(value);
      } catch (e) {
        // Do nothing
      }
    }
    this.updateAction(fieldName, value);
  }

  onChange(evt) {
    const value = evt.target.value;
    const fieldName = evt.target.name;
    this.updateAction(fieldName, value);
  }

  onKeyDown(evt) {
    if (general.enableEnhancedEditingSupportForTextarea(evt)) {
      this.updateAction(evt.target.name, evt.target.value);
    }
  }
}

export default BaseAction;
