import React from 'react';
import Button from '@material-ui/core/Button';

const PrimaryButton = ({ children, type, onClick, disabled, color = 'primary' }) => {
  return <Button
    variant="contained"
    color={color}
    disabled={disabled}
    onClick={onClick}
    type={type}
  >
    {children}
  </Button>
};


export default PrimaryButton;
