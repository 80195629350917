import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userLoginAsync } from '../../asyncActions/authAsyncActions';
import '../../assets/styles/pages/login.scss';
import { Buttons } from '../core';
import queryString from '../../utils/queryString';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Icons } from '../../components/core';

function Login() {
  const searchInput = useRef(null);
  useEffect(()=> {
    // current property is referred to input element
    if (searchInput && searchInput.current)
      searchInput.current.focus();
   },[]);

  const authObj = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const { userLoginLoading, loginError } = authObj;

  const initialUsername = queryString.getParameter('email') || '';

  const username = useFormInput(initialUsername);
  const password = useFormInput('');

  // handle button click of login form
  const handleLogin = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch(userLoginAsync(username.value, password.value));
  };

  return (
    <div className="login-form">
      <form>
        <div className="branding">
          <h1><span>APP</span>GUARD</h1>
          <h2>Customer Login</h2>
        </div>
        <div className="form">
          <div>
            <div><em>Username</em></div>
            <input type="text" name="username" {...username} autoComplete="username" ref={searchInput} />
          </div>
          <div>
            <div><em>Password</em></div>
            <input type="password" name="password" {...password} autoComplete="password" />
          </div>

          <Buttons.PrimaryButton type="submit" disabled={userLoginLoading} onClick={handleLogin}>Login{userLoginLoading ? <CircularProgress size="18" /> : null}</Buttons.PrimaryButton>
          <div className="links"><NavLink to="/forgot">Forgot password?</NavLink></div>
          <div className="error">{loginError ? <><Icons.Error /> {loginError}</> : null}</div>
        </div>
      </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Login;
