import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { TimeRangeSelect, Panel } from "../../../components";
import { AnalyticsAPI } from "../../../api";
import { Icons } from '../../../components/core';
import './home.scss';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import Logs from './Logs';

const COLORS = ['#125293', '#4792db', '#ffbf5f', '#ff6378', '#AF19FF'];

const Home = () => {
  const {user} = useSelector(state => state.auth);
  const [timeRange, setTimeRange] = useState({});
  const [isLoadingGeneralInsights, setIsLoadingGeneralInsights] = useState(false);
  const [generalInsights, setGeneralInsights] = useState({});
  const [isLoadingTriggeredRules, setIsLoadingTriggeredRules] = useState(false);
  const [triggeredRules, setTriggeredRules] = useState([]);

  useEffect(() => {
    if (timeRange.startDate && timeRange.endDate) {
      setIsLoadingGeneralInsights(true);
      setIsLoadingTriggeredRules(true);
      AnalyticsAPI.getGeneralInsights(timeRange.startDate, timeRange.endDate).then( res => {
        setGeneralInsights(res.data || {});
      }).finally(() => {
        setIsLoadingGeneralInsights(false);
      });
      AnalyticsAPI.getTriggeredRules(timeRange.startDate, timeRange.endDate).then( res => {
        setTriggeredRules(res.data || []);
      }).finally(() => {
        setIsLoadingTriggeredRules(false);
      });
    }
  }, [timeRange]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffff', padding: '5px', border: '1px solid #cccc' }}>
          <label>{`${payload[0].name} : triggered ${payload[0].value} times`}</label>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="home-page">
      <div className="header">
        <h2>Overview</h2>
        <TimeRangeSelect onChange={(range) => setTimeRange(range)} />
      </div>
      <div className='general-insights'>
        <Panel icon={Icons.Account} title='Total logs' isLoading={isLoadingGeneralInsights}><h3>{generalInsights.totalLogsCount}</h3></Panel>
        <Panel title='Unique logs' isLoading={isLoadingGeneralInsights}><h3>{generalInsights.uniqueLogsCount}</h3></Panel>
        <Panel title='Unique logs' isLoading={isLoadingGeneralInsights}><h3>{generalInsights.uniqueLogsCount}</h3></Panel>
        <Panel title='Unique logs' isLoading={isLoadingGeneralInsights}><h3>{generalInsights.uniqueLogsCount}</h3></Panel>
      </div>
      <div className='latest-logs'>
        <Panel title='Latest logs' titleType="top">
          <Logs mode='LATEST' startDate={timeRange.startDate} endDate={timeRange.endDate} />
        </Panel>
      </div>
      <div className='most-frequent-logs'>
        <Panel title='Most frequent logs' titleType="top">
          <Logs mode='FREQUENT' startDate={timeRange.startDate} endDate={timeRange.endDate} />
        </Panel>
      </div>
      <div className='rules-insights'>
        <Panel title='Triggered rules' isLoading={isLoadingTriggeredRules} titleType="top">
            <PieChart width={300} height={300}>
              <Pie data={triggeredRules} dataKey="triggered" nameKey="name" isAnimationActive={false}  fill="#8884d8" label >
                {
                  triggeredRules.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend height={20} />
            </PieChart>
        </Panel>
        <Panel title='Another chart' isLoading={isLoadingTriggeredRules} titleType="top">
          <PieChart width={300} height={300}>
              <Pie data={triggeredRules} dataKey="triggered" nameKey="name" isAnimationActive={false}  fill="#8884d8" label >
                {
                  triggeredRules.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                }
              </Pie>
              <Tooltip content={<CustomTooltip />} />
              <Legend height={20} />
            </PieChart>
        </Panel>
      </div>
    </div>
  );
};

export default Home;
