import React from 'react';
import MetadataAPI from '../../../../api/metadata';
import queryString from '../../../../utils/queryString';

const OAuth2Authorize = (props) => {

  const type = props.match.params.type;
  const code = queryString.parseQueryString(window.location.href)["code"];
  MetadataAPI.authorizeOAuth2(type, code).then(res => {
    window.close();
  });
  
  return (
  <main style={{"paddingTop": "calc(50vh - 20px)", "text-align": "center"}}>
    Storing authorization...
  </main>
)};

export default OAuth2Authorize;

