import React, { useEffect, useRef, useState } from 'react';
import { userForgotPasswordService } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import { Buttons, Icons } from '../core';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

function Forgot() {
  const searchInput = useRef(null);
  useEffect(()=> {
    // current property is referred to input element
    if (searchInput && searchInput.current)
      searchInput.current.focus();
   },[]);

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(null);
  const [completedSuccessfully, setCompletedSuccessfully] = useState(false);

  const username = useFormInput('');

  // handle button click of login form
  const handleForgot= (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setBusy(true);
    userForgotPasswordService(username.value).then(({success, data, error}) => {
      setBusy(false);
      if (!success)
        setError(error);
      else
        setCompletedSuccessfully(true);
    });
  };

  return (
    <div className="login-form">
      <form>
        <div className="branding">
            <h1><span>APP</span>GUARD</h1>
            <h2>Forgot Password</h2>
          </div>
          <div className="form">
              {completedSuccessfully ? <div className="links">Email sent successfully<br /><br /><NavLink to={"/login?email=" + username.value}>Log in</NavLink></div> : <>
              <div>
                <div><em>Email</em></div>
                <input type="text" {...username} autoComplete="username" ref={searchInput} />
              </div>
                <Buttons.PrimaryButton type="submit" disabled={busy} onClick={handleForgot}>Send{busy ? <CircularProgress size="18" /> : null}</Buttons.PrimaryButton>
              <div className="links"><NavLink to="/login">Log into your account</NavLink></div>
              <div className="error">{error ? <><Icons.Error /> {error}</> : null}</div>
              </>}
        </div>
      </form>
    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Forgot;
