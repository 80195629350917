import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import ClickOutsideHelper from '../helpers/ClickOutsideHelper';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { Icons } from '../../components/core';
import { switchOrganizationAsync } from '../../asyncActions/authAsyncActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';


const SidebarOrganizationButton = ({enableSwitchOrganization, currentOrganizationName, toggleOpen}) => {

  if (enableSwitchOrganization) {
      return <>
        <div className='account button' onClick={toggleOpen}>
          <span>{currentOrganizationName}</span>
          <ListItemIcon className="expand-collapse"><Icons.Collapse /></ListItemIcon>
        </div>
        </>;
    }

    return <div className='account'>{currentOrganizationName}</div>;
};

const SidebarOrganizationMenu = ({enableSwitchOrganization, organizations, toggleOpen}) => {
  const dispatch = useDispatch();
  const handleSwitchAccount = (orgId) => {
    if (orgId) {
      dispatch(switchOrganizationAsync(orgId));
      toggleOpen();
    }
  };

  if (enableSwitchOrganization) {
      return (<div className="submenu">
          <div className="group">SWITCH ACCOUNT</div>
          {organizations.slice(1).map((org, index) => {
              return <NavLink key={'account_' + index} to="/"
                          className='side-bar-link level-2'
                          onClick={() => handleSwitchAccount(org.id)}>
                <ListItem button>
                  <ListItemText primary={org.name} />
                </ListItem>
              </NavLink>;
          })}
      </div>);
  }
  return null;
};


const SwitchOrganization = ({items, className}) => {
  const wrapperRef = useRef(null);
  ClickOutsideHelper(wrapperRef, () => setIsOpen(false));
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user } = useSelector(state => state.auth);
  if (!isAuthenticated || !user)
    return null;

  const enableSwitchOrganization = user.Organizations.length > 1;
  const currentOrganizationName = user.Organizations[0] && user.Organizations[0].name;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (<div ref={wrapperRef} className={"list-item-group" + (isOpen ? ' opened' : '')}>
        <List disablePadding>
          <Divider />
          <SidebarOrganizationButton enableSwitchOrganization={enableSwitchOrganization} currentOrganizationName={currentOrganizationName} toggleOpen={toggleOpen} />
          <SidebarOrganizationMenu enableSwitchOrganization={enableSwitchOrganization} organizations={user.Organizations} toggleOpen={toggleOpen} />
          <Divider />
        </List>
    </div>);
};

export default SwitchOrganization;
