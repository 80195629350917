import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal as ReactModal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Draggable from '../helpers/Draggable';
import Editable from './Editable';
import general from '../../utils/general';
import '../../assets/styles/components/editors.scss';
import { MdPlayArrow } from 'react-icons/md';
import SendEmailAction from './actions/SendEmailAction';
import SendSlackAction from './actions/SendSlackAction';
import SetVariableAction from './actions/SetVariableAction';
import GetSlackUserByEmailAction from './actions/GetSlackUserByEmailAction';
import $ from 'jquery';
import CreateJiraTicketAction from './actions/CreateJiraTicketAction';
import SendTelegramNotificationAction from './actions/SendTelegramNotificationAction';
import SendRestAPICallAction from './actions/SendRestAPICallAction';
import MetadataAPI from '../../api/metadata';

class ActionsDrawer extends React.Component {

  static propTypes = {
    actions: PropTypes.array.isRequired,
    availableProviders: PropTypes.array.isRequired,
    onChange: PropTypes.func
  };

  state = {
    actions: null,
    testModal: {
      open: false,
      log: { level: 'info', message: 'message'},
      action: null,
      actionResult: null,
      availableActions: filterAvailableActions()
    }
  };

  static getDerivedStateFromProps(nextProps, nextContext) {
    return {
      actions: nextProps.actions,
      availableActions: filterAvailableActions(nextProps.availableProviders)
    }
  }

  isValidIntegration(provider) {
    const availableAction = this.state.availableActions.find(action => action.provider === provider);
    return availableAction && availableAction.validIntegration;
  }

  renderButtons() {
    return <Row className="criteria-buttons" style={{marginLeft: 0}}>
              <div>
                <select className="form-control" value="none" onChange={(e) => this.addSimple(e)}>
                  <option name='none' value="none">Add action</option>
                  {this.state.availableActions.map((a,index) => <option key={'a_action_' + index} value={a.type} data-provider={a.provider}>{a.label}</option>)}
                </select>
              </div>
            </Row>;
  }

  getActionsSelector(action, actionIndex) {
    const provider = action && action.provider;
    const type = action && action.type;

    return<div>
      <select className="form-control" value={`${provider}-${type}`} onChange={(e) => this.onActionChanged(e, action, actionIndex)}>
      {this.state.availableActions.map((a,index) => <option key={'a_action_' + index} data-type={a.type} data-provider={a.provider} value={`${a.provider}-${a.type}`}>{a.label}</option>)}
      </select>
    </div>;
  }

  getActionEditor(action, actionIndex) {
    const provider = action && action.provider;
    const type = action && action.type;

    switch (`${provider}.${type}`) {
      case 'System.sendEmail':
        return <SendEmailAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;
      case 'System.setVariable':
        return <SetVariableAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;
      case 'System.RestAPI':
        return <SendRestAPICallAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;

      case 'Slack.notify':
        return <SendSlackAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;
      case 'Slack.getUserByEmail':
        return <GetSlackUserByEmailAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;

      case 'Jira.createIssue':
        return <CreateJiraTicketAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;

      case 'Telegram.notify':
        return <SendTelegramNotificationAction action={action} onChange={(action) => this.updateAction(action, actionIndex)} />;

      default:
        return <Editable
          type="textarea"
          name="action"
          label="Data"
          value={action}
          onChange={(action) => this.updateAction(action, actionIndex)}  />
    }
  }

  renderAction(action, index){
    return <Draggable key={`action_${index}`} draggable index={index} onDrop={this.onDrop} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
      <div className="actions-container">
        <div className="actions-header">
          <span className="drag-handle">::</span>
          {this.getActionsSelector(action, index)}
          <strong onClick={() => this.onDeleteRow(index)} title='Delete section'>x</strong>
        </div>
        {!this.isValidIntegration(action.provider) && <div className="integration-error">Invalid integration</div>}
        <div><label><input type="checkbox" name="disabled" checked={!action.disabled} value={!action.disabled} onChange={(e) => this.onActionAttributeChanged(e, index)} />Active</label></div>
      </div>
      <div className="actions-container">
        {this.getActionEditor(action, index)}
      </div>
      <div className="actions-container">
        <label><input type="checkbox" name="abortOnFailure" checked={!!action.abortOnFailure} value={!action.abortOnFailure} onChange={(e) => this.onActionAttributeChanged(e, index)} /><span>Abort processing on failure</span></label>
        <button className="form-control" onClick={() => this.testAction(action)}><span>Test action</span><MdPlayArrow/></button>
      </div>
    </Draggable>;
  }

  renderActions(actions) {
    if (Array.isArray(actions))
      return actions.map((action,index) => this.renderAction(action, index));
    return null;
  }

  render() {
    return <div className='criteria-drawer'>
      {this.renderActions(this.state.actions)}
      {this.renderButtons()}
      {this.renderTestActionModal()}
    </div>;
  }

  setModalState(stateName, value) {
    const testModal = {...this.state.testModal};
    testModal[stateName] = value;
    this.setState({testModal});
  }

  formatResult = (result) => {
    // return JSON.stringify(result);
    if (!result) return result;
    const res = [];
    if (result.success) {
      res.push(<div><strong style={{color: 'green'}}>Succeeded</strong></div>);
    }
    else {
      res.push(<div><strong style={{color: 'red'}}>Failed</strong> - <strong>{result.errorType} error</strong>: {result.error}</div>);
    }
    if (Array.isArray(result.auditTraces)) {
      res.push(<div>Traces:</div>);
      res.push(...result.auditTraces.map((at, index) => <div key={index}><strong>{at.type}</strong>: {at.message}</div>));
    }
    return res;
  };

  renderTestActionModal() {
    const { open, log, action, actionResult } = this.state.testModal;
    const closeModalHandler = () => this.setModalState('open', false);

    return <ReactModal
        isOpen={open}
        toggle={closeModalHandler}
        className='modal-action-test'>
      <ModalHeader toggle={closeModalHandler}>Test action</ModalHeader>
      <ModalBody>
        <div>
          <em>Log:</em>
          <Editable
            type="textarea"
            name="log"
            value={log}
            onChange={(evt) => this.setModalState(evt.target.name, evt.target.value)} />
          <em>Result:</em>
          <div className="action-result">{this.formatResult(actionResult)}</div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          // Call API for testing purposes
          this.setModalState('actionResult', null);
          MetadataAPI.testAction(action, log).then(({success, data}) => {
            this.setModalState('actionResult', data);
          });
        }}>
          Test Action
        </Button>{' '}
        <Button color="secondary" onClick={closeModalHandler}>
          Cancel
        </Button>
      </ModalFooter>
    </ReactModal>;
  }

  testAction = (action) => {
    this.setState({
      testModal: {
        open: true,
        log: { level: 'info', message: 'message'},
        action,
        actionResult: ''
      }
    });
  };

  onDrop = (dragIndex, dropIndex) => {
    const actions = general.clone(this.state.actions);
    general.reorder(actions, dragIndex, dropIndex);
    this.updateActions(actions);
  };

  onDragStart = (evt) => {
    $('.save-changes-bar').hide();
  };

  onDragEnd = (evt) => {
    $('.save-changes-bar').show();
  };

  onDeleteRow = (actionIndex) => {
    const actions = general.clone(this.state.actions);
    actions.splice(actionIndex, 1);
    this.updateActions(actions);
  };

  addSimple = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const type = selectedOption.value;
    if (type !== 'none') {
      const provider = selectedOption.dataset['provider'];
      const actions = general.clone(this.state.actions);
      actions.push({provider, type});
      this.updateActions(actions);
    }
  };

  onActionAttributeChanged(e, actionIndex) {
    const actions = general.clone(this.state.actions);
    const fieldName = e.target.name;
    actions[actionIndex][fieldName] = e.target.value === 'true';
    this.updateActions(actions);
  }

  onActionChanged(e, action, actionIndex) {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const type = selectedOption.dataset.type;
    if (type !== action.type) {
      const provider = selectedOption.dataset['provider'];
      const actions = general.clone(this.state.actions);
      actions.splice(actionIndex, 1, {provider, type});
      this.updateActions(actions);
    }
  }

  updateAction(action, actionIndex) {
    const actions = general.clone(this.state.actions);
    actions[actionIndex] = action;
    this.updateActions(actions);
  }

  updateActions(actions) {
    this.setState({ actions });
    if (this.props.onChange)
      this.props.onChange(actions);
  }
}

export default ActionsDrawer;

function filterAvailableActions(providers) {
  return _availableActions.map(action => {
    return {
      ...action,
      validIntegration: (providers || []).includes(action.provider.toLowerCase())
    };
  });
}

const _availableActions = [
  {provider: 'Slack', type: 'getUserByEmail', label: 'Get Slack user by email', validIntegration: true},
  {provider: 'Slack', type: 'notify', label: 'Send Slack notification', validIntegration: true},
  {provider: 'System', type: 'sendEmail', label: 'Send email', validIntegration: true},
  {provider: 'System', type: 'setVariable', label: 'Set Variable', validIntegration: true},
  {provider: 'Jira', type: 'createIssue', label: 'Create Jira Issue', validIntegration: true},
  {provider: 'Telegram', type: 'notify', label: 'Send Telegram notification', validIntegration: true},
  {provider: 'System', type: 'RestAPI', label: 'Send RestAPI call', validIntegration: true}
];
