import axios from 'axios';

class MetadataAPI {

  // Integrations
  fetchIntegrations() {
    return axios.get(`/metadata/integrations`);
  }

  fetchIntegration(id) {
    return axios.get(`/metadata/integrations/${id}`);
  }

  getOAuth2Url(type) {
    return axios.get(`/metadata/integrations/${type}/info`);
    // return new Promise((resolve) => {
    //   resolve('https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=vD4bK80GFEnqFgUbUZgLU6sQMr54eC3l&scope=read%3Ajira-user%20read%3Ajira-work%20write%3Ajira-work&redirect_uri=https%3A%2F%2Fdev.appguard.com%2Fadmin%2Fauth%2Fauthorize%2FJira&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent');
    // });
  }

  authorizeOAuth2(type, code) {
    return axios.post(`/metadata/integrations/${type}/auth/${code}`);
  }

  revokeIntegration(integrationId) {
    return axios.post(`/metadata/integrations/${integrationId}/revoke`);
  }

  upsertIntegration(id, data) {
    if (id)
      return this.updateIntegration(id, data);
    return this.addNewIntegration(data);
  }

  addNewIntegration(newIntegration) {
    return axios.put(`/metadata/integrations`, newIntegration);
  }

  updateIntegration(id, updatedIntegration) {
    return axios.post(`/metadata/integrations/${id}`, updatedIntegration);
  }


  getAvailableActionProviders() {
    return axios.get('/metadata/providers');
  }

  // Incident flows
  getAllIncidentFlows() {
    return axios.get('/metadata/rules');
  }

  getIncidentFlow(id) {
    return axios.get(`/metadata/rules/${id}`);
  }

  createIncidentFlow(flow) {
    return axios.put('/metadata/rule', flow);
  }

  deleteIncidentFlow(id) {
    return axios.delete(`/metadata/rule/${id}`);
  }

  updateIncidentFlow(id, flow) {
    return axios.post(`/metadata/rule/${id}`, flow);
  }

  saveIncidentFlowsBulk(flows) {
    return axios.post('/metadata/rule/bulk', flows);
  }

  testAction(action, log) {
    return axios.post('/metadata/action/test', { action, log });
  }

  getSlackChannels() {
    return axios.get('/metadata/integrations/slack/channels/list');
  }

}

export default new MetadataAPI();
