import React from 'react';
import BaseAction from './BaseAction';

class CreateJiraTicketAction extends BaseAction {

  get IssueTypes() {
    return [
      {value: 'task', label: 'Task'},
      {value: 'bug', label: 'Bug'},
      {value: 'userstory', label: 'User Story'}
    ];
  }

  renderAction(action) {
    return <div>
      {this.renderInput(action, 'Project', 'projectKey')}
      {this.renderSelect(action, 'Issue Type', 'issueType', this.IssueTypes, 'task')}
      {this.renderInput(action, 'Assigned to', 'assignedToEmail')}
      {this.renderTextarea(action, 'Fields', 'fields')}
    </div>;
  }
}

export default CreateJiraTicketAction;