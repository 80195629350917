import React from "react";
import PageSpinner from "../PageSpinner";
import MetadataAPI from "../../api/metadata";
import {MdErrorOutline, MdCheckCircle, MdEdit, MdDelete, MdLink, MdAddCircleOutline} from "react-icons/all";
import ModalManager from "../../utils/modalManager";
import { OAuth2Integration } from './components/integrations';
import '../../assets/styles/pages/integrations.scss';
import general from '../../utils/general'

class Integrations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      integrations: []
    };
  }

  componentWillMount() {
    this.refresh();
  }

  refresh(onAfterRefresh) {
    MetadataAPI.fetchIntegrations().then(({success, data}) => {
      if (success)
        this.setState({integrations: data, loading: false}, onAfterRefresh);
    });
    return true;
  }

  revokeIntegration(integration) {
    const {type, id} = integration;
    if (id && type) {

      const revoke = () => {
        MetadataAPI.revokeIntegration(id).then(({success, data}) => {
          if (success) {
            ModalManager.closeModalById(modalId);
            const integrations = general.clone(this.state.integrations.filter(i => i.id !== data.id));
            integrations.push(data);
            this.setState({integrations});
          }
        });
      };

      const modalButtons = {
                        "Revoke" : { handler: revoke },
                        "Cancel" : {primary: true}
                       };

      const content = <div>
        Are you sure you would like to revoke the {type} integration?
      </div>;
      let config = {
          header: `Revoke your ${type} integration`,
          message: content,
          footer: "footer",
          handlers: modalButtons
      };
      const modalId = ModalManager.createModal(config);
    }
  }

  handleIntegration(type, integration) {
    let Component = OAuth2Integration,
      modalButtons = {
      "Close" : { primary: true }
    };
    const data = {type};
    // const saveData = () => {
    //   // Need to save new data
    //   const integrationId = integration && integration.id;
    //   if (integrationId)
    //     delete data.type;
    //   MetadataAPI.upsertIntegration(integrationId, data).then(({success, data}) => {
    //     if (success) {
    //       const integrations = this.state.integrations.filter(i => i.id !== data.id).cloneMe();
    //       integrations.push(data);
    //       this.setState({integrations});
    //       ModalManager.closeModalById(modalId);
    //     }
    //   });
    //   return false;
    // };

    const onSuccess = () => {
      this.refresh(() => {
        const integration = this.state.integrations.find(i => i.type === type);
        const isValid = (integration && integration.isValid) || false;
        if (isValid)
          ModalManager.closeModalById(modalId);
      });
    };

    const content = <div>
      <Component data={integration} type={type} handleStateChange={(key, value) => data[key] = value} onSuccess={onSuccess} />
    </div>;
    let config = {
        header: `Connect ${type}`,
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    const modalId = ModalManager.createModal(config);
  }

  getIntegrationActions(integration) {
    return <div className="actions">
        {integration && integration.isValid ? <MdEdit size={20} /> : <MdAddCircleOutline size={20} />}
      </div>;
  }

  getIntegrationStatus(integration) {
    if (!integration || (!integration.isValid && !integration.lastError))
      return <div className="status"><span className="status_connect"><MdLink size={20} /> <em>Connect now</em></span></div>;
    return <div className="status">
      {integration.isValid ? <span className="status_valid"><MdCheckCircle size={20} /> <em>Connected</em></span> : <span className="status_invalid"><MdErrorOutline size={20} /> <em>{integration.lastError || "Validation error"}</em></span> }
    </div>;
  }

  renderIntegration(type) {
    const integration = this.state.integrations.find(i => i.type === type);
    return <div className="integration">
      <div>
        {this.getIntegrationActions(integration)}
        <button className={type} onClick={() => this.handleIntegration(type, integration)}>
        </button>
        {this.getIntegrationStatus(integration)}
      </div>
      {integration && integration.isValid ? <div className="revoke"><button type="button" onClick={() => this.revokeIntegration(integration)}><MdDelete size={20} /><span>Revoke</span></button></div> : null}
    </div>;
  }

  render() {
    if (this.state.loading)
      return <PageSpinner />;

    return <div className="integrations-page">
        <div className="integrations-container">
          {this.renderIntegration('Slack')}
          {this.renderIntegration('Jira')}
        </div>
      </div>;
    }

}

export default Integrations;
