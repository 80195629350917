import axios from 'axios';

class IntegrationsAPI {

  fetchIntegrations() {
    return axios.get(`/integrations`);
  }

  fetchIntegration(id) {
    return axios.get(`/integrations/${id}`);
  }

  getOAuth2Url(type) {
    return axios.get(`/integrations/${type}/info`);
    // return new Promise((resolve) => {
    //   resolve('https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=vD4bK80GFEnqFgUbUZgLU6sQMr54eC3l&scope=read%3Ajira-user%20read%3Ajira-work%20write%3Ajira-work&redirect_uri=https%3A%2F%2Fdev.appguard.com%2Fadmin%2Fauth%2Fauthorize%2FJira&state=${YOUR_USER_BOUND_VALUE}&response_type=code&prompt=consent');
    // });
  }

  authorizeOAuth2(type, code) {
    return axios.post(`/integrations/${type}/auth/${code}`);
  }

  revokeIntegration(integrationId) {
    return axios.post(`/integrations/${integrationId}/revoke`);
  }

  upsertIntegration(id, data) {
    if (id)
      return this.updateIntegration(id, data);
    return this.addNewIntegration(data);
  }

  addNewIntegration(newIntegration) {
    return axios.put(`/integrations`, newIntegration);
  }

  updateIntegration(id, updatedIntegration) {
    return axios.post(`/integrations/${id}`, updatedIntegration);
  }

  getTelegramChatId(chatName) {
    return axios.get(`/utils/telegram/chatid?name=${chatName}`);
  }

}

export default new IntegrationsAPI();
