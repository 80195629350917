import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Icons } from "../index";

let optionIndex = 0;

export default ({ options = [], onOptionSelected, className }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const onAnchorClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (option) => {
    handleClose();
    if (onOptionSelected)
      onOptionSelected(option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className} >
      <Icons.More onClick={onAnchorClick} />
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem key={`option_${optionIndex++}`} selected={option.selected} onClick={() => handleMenuItemClick(option)}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

