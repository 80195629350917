import React from 'react';
import MetadataAPI from '../../../../api/metadata';

const OAuth2Redirect = (props) => {

  const type = props.match.params.type;
  MetadataAPI.getOAuth2Url(type).then(({success, data}) => {
    if (success)
      window.location.href = data;
  });

  return (
  <main style={{"paddingTop": "calc(50vh - 20px)", "text-align": "center"}}>
    Redirecting to {type} for authorization...
  </main>
)};

export default OAuth2Redirect;
