import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

class SlackIntegration extends React.Component {

  constructor(props) {
    super(props);
    
    this.onChange = this.onChange.bind(this);
    
    this.state = {
      webhookUrl: props.data.webhookUrl,
      channelName: props.data.channelName
    };
    
    props.handleStateChange('webhookUrl', this.state.webhookUrl);
    props.handleStateChange('channelName', this.state.channelName);
  }
  
  onChange({target}) {
    const {name, value} = target;
    this.setState({[name]: value});
    this.props.handleStateChange(name, value);
  }
  
  render() {
    setTimeout(() => {
      $('.slack-integration-form input').first().focus();
    }, 0);
    return <div className="slack-integration-form">
      <div>
        <em>Webhook url:</em>
        <input name="webhookUrl" value={this.state.webhookUrl} onChange={this.onChange} /></div>
      <div>
        <em>Channel name:</em>
        <input name="channelName" value={this.state.channelName} onChange={this.onChange} /></div>
    </div>;
  }
}

SlackIntegration.defaultProps = {
  data: {
    webhookUrl: '',
    channelName: ''
  }
};

SlackIntegration.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  handleStateChange: PropTypes.func.isRequired
};

export default SlackIntegration;
