import React from 'react';
import Dialog from './Dialog';
import { Buttons } from '../core';

const Confirm = ({ open, title, onYes, onClose, className, children, yesText='Ok', noText='Cancel' }) => {
  return <Dialog
    open={open}
    header={title}
    onClose={onClose}
    className={className}
    modal={true}
    footer={
      <div>
        <Buttons.Button variant='outlined' onClick={onClose} >{noText}</Buttons.Button>
        <Buttons.PrimaryButton onClick={onYes} >{yesText}</Buttons.PrimaryButton>
      </div>
    }
  >
    {children}
  </Dialog>;
};

export default Confirm;
