class General {
  static hasFlag(value, flag) {
    return (value &= flag) === flag;
  }

  static removeFlag(value, flag) {
    return value &= ~flag;
  }

  static addFlag(value, flag) {
    return value |= flag;
  }

  static getValueArrayFromFlag(enumValues, flag) {

    const valueArray = [];
    enumValues.forEach(v => {
      if (this.hasFlag(flag, +v))
        valueArray.push(v);
    });
    return valueArray;
  }

  static getFlagValueFromArray(valueArray) {
    let flag = 0;
    valueArray.forEach(v => flag = this.addFlag(flag, v));
    return flag;
  }

  static enableEnhancedEditingSupportForTextarea(evt, useSpaces = true) {
		// Enter Key?
    const {target, keyCode, shiftKey} = evt;
		// if (keyCode === 13)
		// {
		//   evt.preventDefault();
		// 	// selection?
		// 	if (target.selectionStart === target.selectionEnd)
		// 	{
		// 		// find start of the current line
		// 		let sel = target.selectionStart;
		// 		const text = target.value;
		// 		while (sel > 0 && text[sel-1] !== '\n')
		// 		  sel--;
    //
		// 		const lineStart = sel;
    //     while (text[sel] === ' ' || text[sel]==='\t')
		// 		  sel++;
    //
		// 		if (sel > lineStart)
		// 		{
		// 			// Insert carriage return and indented text
		// 			document.execCommand('insertText', false, "\n" + text.substr(lineStart, sel-lineStart));
    //
		// 			// Scroll caret visible
		// 			target.blur();
		// 			target.focus();
		// 			return true;
		// 		}
		// 	}
		// }

		// Tab key?
		if(keyCode === 9)
		{
			// selection?
			if (target.selectionStart === target.selectionEnd)
			{
				// These single character operations are undoable
				if (!shiftKey)
				{
					document.execCommand('insertText', false, useSpaces ? '    ' : '\t');
				}
				else
				{
					const text = target.value;
					if (target.selectionStart > 0) {
					  if (text[target.selectionStart-1] === '\t') {
					    document.execCommand('delete');
            } else {
					    let maxLength = 4;
					    while (text[target.selectionStart-1] === ' ' && maxLength) {
					      maxLength--;
					      document.execCommand('delete');
              }
            }
          }
				}
			}
			else
			{
				// Block indent/unindent trashes undo stack.
				// Select whole lines
				let selStart = target.selectionStart;
				let selEnd = target.selectionEnd;
				const text = target.value;
				while (selStart > 0 && text[selStart-1] !== '\n')
					selStart--;
				while (selEnd > 0 && text[selEnd-1]!=='\n' && selEnd < text.length)
					selEnd++;

				// Get selected text
				let lines = text.substr(selStart, selEnd - selStart).split('\n');

				// Insert tabs
				for (let i=0; i<lines.length; i++)
				{
					// Don't indent last line if cursor at start of line
					if (i===lines.length-1 && lines[i].length===0)
						continue;

					// Tab or Shift+Tab?
					if (shiftKey)
					{
						if (lines[i].startsWith('\t'))
							lines[i] = lines[i].substr(1);
						else if (lines[i].startsWith("    "))
							lines[i] = lines[i].substr(4);
					}
					else
						lines[i] = (useSpaces ? '    ' : '\t') + lines[i];
				}
				lines = lines.join('\n');

				// Update the text area
				target.value = text.substr(0, selStart) + lines + text.substr(selEnd);
				target.selectionStart = selStart;
				target.selectionEnd = selStart + lines.length;
			}
			evt.preventDefault();
			return true;
		}
		return false;
  }

  static reorder(arr, currentIndex, newIndex) {
    if (Array.isArray(arr) && currentIndex >= 0 && arr.length > currentIndex) {
      const item = arr.splice(currentIndex, 1)[0];
      arr.splice(newIndex, 0, item);
    }
  }

  static clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static isDeepEqual(obj, obj2) {
    return JSON.stringify(obj) === JSON.stringify(obj2);
  }
}

export default  General;
