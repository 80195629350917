import React, {useState} from 'react';

import './Settings.scss';
import { Container, Row } from "reactstrap";
import Editable from "../../editors/Editable";
import general from "../../../utils/general";
import {useDispatch, useSelector} from "react-redux";
import SaveChangesBar from "../../SaveChangesBar";
import { UsersAPI } from "../../../api";
import { updateUser } from '../../../actions/authActions';
import { CollapsiblePanel, Checkbox } from '../../core';


const _defaultOrganizationOptions = {
  minTimeBetweenLogs: 5,
  widget_showDialog: false,
  widget_screenshot: false,
  widget_reportError: false,
  widget_allowFeedback: false,
  widget_domains: ''
};

const Settings = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const currentOrganization = user.Organizations.find(org => org.name === user.currentOrgName);
  const currentOptions = currentOrganization.options || _defaultOrganizationOptions;
  const [options, setOptions] = useState(currentOptions);

  const changeOption = (optionName, optionValue) => {
    setOptions({
      ...options,
      [optionName]: optionValue
    });
  };

  const saveChanges = () => {
    if (options.widget_domains) {
      options.widget_domains = filterValidDomains().join('\n');
      setOptions(options);
    }

    return UsersAPI.updateOrganizationOptions(currentOrganization.id, options).then(result => {
      const org = user.Organizations.find(o => o.id === currentOrganization.id);
      org.options = result.data.options;
      dispatch(updateUser(user));
      return true;
    });
  };

  const filterValidDomains = () => {
    const res = [];
    if (options.widget_domains) {
      const domains = options.widget_domains.replace(/[;,\n ]/g, ';').split(';').filter(s => !!s);
      domains.forEach(d => {
        d = d.replace('http://', '').replace('https://', '');
        d = d.split('/')[0];
        if (isValidDomain(d))
          res.push(d);
      });
    }
    return res;
  };

  const isValidDomain = (domain) => {
    return /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/.test(domain);
  };

  const generateScriptTag = () => {
    const params = {
      sourceId: currentOrganization.sourceId,
      sourceName: currentOrganization.name,
      source: 'Application'
    };
    if (options.widget_showDialog) {
      params.dlg = 1;
      if (options.widget_allowFeedback)
        params.fdbk = 1;
    }
    if (options.widget_screenshot)
      params.scrn = 1;
    if (options.widget_reportError)
      params.log = 1;

    if (options.widget_domains) {
      const domains = filterValidDomains().join(';');
      params.dmns = encodeURIComponent(domains);
    }
    const qs = Object.keys(params).map(p => `${p}=${params[p]}`).join('&');
    return `<script id="AppGuardInclude" rel="noopener noreferrer" src="https://widgets.appguard.app/acetm.js?${qs}"></script>`;
  };

  const hasChanges = !general.isDeepEqual(options, currentOptions);
  return (
    <div className='settings-page'>
      <Container>
        <Row className='source-id-cnt'>
          <span className='field-label'>Source id to use in the logs:</span>
          <span className='source-id'>{user && user.Organizations && user.Organizations[0] && user.Organizations[0].sourceId}</span>
        </Row>
        <Row>
          <CollapsiblePanel title='Global settings'>
            <Editable
              type="text"
              name="minTimeBetweenLogs"
              label="Min time between logs"
              value={options['minTimeBetweenLogs']}
              placeholder="2d, 6h, 5m"
              className="minTimeBetweenLogs"
              onChange={(e) => changeOption('minTimeBetweenLogs', e.target.value) } />
          </CollapsiblePanel>
         </Row>
          <Row>
            <CollapsiblePanel title='End user widget'>
              <Container className='widget-settings'>
              <Row>
                <Checkbox label="Show dialog"
                  checked={options['widget_showDialog']}
                  onChange={(e) => changeOption('widget_showDialog', e.target.checked)}
                />
              </Row>
              <Row>
                <Checkbox label="Allow client feedback"
                          disabled={!options['widget_showDialog']}
                          checked={options['widget_showDialog'] && options['widget_allowFeedback']}
                          onChange={(e) => changeOption('widget_allowFeedback', e.target.checked)}
                />
              </Row>
              <Row>
                <Checkbox label="Take screenshot"
                          checked={options['widget_screenshot']}
                          onChange={(e) => changeOption('widget_screenshot', e.target.checked)}
                />
              </Row>
              <Row>
                <Checkbox label="Report client errors"
                          checked={options['widget_reportError']}
                          onChange={(e) => changeOption('widget_reportError', e.target.checked)}
                />
               </Row>
              <Row className='widget-domains-cnt'>
                  <div className='field-label'>Domains whitelist</div>
                  <textarea className="form-control" value={options['widget_domains']}
                            onChange={(e) => changeOption('widget_domains', e.target.value) } />
              </Row>
              <Row className='widget-script-row'>
                  <div className='field-label'>Your script tag</div>
                  <div className='widget-script'>{generateScriptTag()}</div>
              </Row>
              </Container>
            </CollapsiblePanel>
         </Row>
      </Container>

      <SaveChangesBar HasChanges={hasChanges} OnSave={() => saveChanges()} />
    </div>
  );
}


export default Settings;
