import axios from 'axios';

class AnalyticsAPI {
  constructor() {
    this.baseUrl = '/analytics';
  }

  getGeneralInsights(startDate, endDate) {
    return axios.get(`${this.baseUrl}/general`, { params: { startDate, endDate }});
  }

  getTriggeredRules(startDate, endDate) {
    return axios.get(`${this.baseUrl}/rules/triggered`, { params: { startDate, endDate }});
  }

  getLatestLogs(startDate, endDate) {
    return axios.get(`${this.baseUrl}/logs/latest`, { params: { startDate, endDate }});
  }

  getMostFrequentLogs(startDate, endDate) {
    return axios.get(`${this.baseUrl}/logs/most_frequent`, { params: { startDate, endDate }});
  }

}

export default new AnalyticsAPI();
