import React from 'react';
import BaseAction from './BaseAction';
import MetadataAPI from '../../../api/metadata';

class SendSlackAction extends BaseAction {

  getAdditionalData() {
    MetadataAPI.getSlackChannels().then(({success, data: channels}) => {
      if (success) {
        const additionalData = {channels};
        this.setState({additionalData});
      }
    });
  }

  renderAction(action) {
    const values = (this.state.additionalData?.channels || []).map(channel => ({value: channel.id, label: channel.name}));
    const channel = values.find(val => val.label === 'client_messages') || values[0];
    const defaultChannel = channel ? channel.value : '';
    action.data = action.data || {};
    if (!Object.keys(action.data).length) {
      action.data['text'] = 'The message to send';
    }
    action.channel = action.channel || defaultChannel;
    return <div>
      {this.renderSelect(action, 'Channel', 'channel', values, defaultChannel)}
      {this.renderTextarea(action, 'Data', 'data')}
    </div>;
  }
}

export default SendSlackAction;
