import React, { useEffect, useRef, useState } from 'react';
import { validateSignature } from '../../api/auth';
import '../../assets/styles/pages/login.scss';
import PageSpinner from '../PageSpinner';
import { userReset } from '../../api/auth';
import {NavLink, useHistory} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Buttons } from '../core';

function Reset({ match }) {
  const history = useHistory();
  const verification = match.params?.sig;
  const searchInput = useRef(null);
  useEffect(()=> {
   // current property is referred to input element
    if (searchInput && searchInput.current)
      searchInput.current.focus();
   },[]);

  const [userData, setUserData] = useState(false);
  const [resetPasswordBusy, setResetPasswordBusy] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const password = useFormInput('');

  useEffect(() => {
    validateSignature(verification).then(({success, data}) => {
      if (success)
        setUserData(data);
      else
        history.push('/login');
    });
  }, [verification, history]);

  // checking authentication
  if (!userData) {
    return <PageSpinner caption="Loading..." />
  }

  const handleReset = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setResetPasswordBusy(true);
    userReset(userData.email, password.value, verification).then(({success}) => {
      if (success) {
        setResetPasswordSuccess(true);
      }
      else {
        setResetPasswordBusy(false);
        setResetPasswordError("There was a problem changing your password");
      }
    });
  };

  return (
    <div className="login-form">
      <form>
        <div className="branding">
          <h1><span>APP</span>GUARD</h1>
          <h2>Forgot password</h2>
        </div>
        <div className="form">
          {resetPasswordSuccess ?
            <div className="reset-success">Password changed successfully<br /><br />
            <NavLink exact to={"/login?email=" + userData.email}>Log into your account</NavLink></div> :
          <>
          <div>
            <div><em>Email</em></div>
            <div>{userData.email}</div>
          </div>
          <div className="m-top">
            <div><em>Password</em></div>
            <input type="password" {...password} autoComplete="new-password" ref={searchInput} />
          </div>
          <Buttons.PrimaryButton type="submit" disabled={resetPasswordBusy} onClick={handleReset}>Change Password{resetPasswordBusy ? <CircularProgress size="18" /> : null}</Buttons.PrimaryButton>
          <div className="links"><NavLink to="/login">Login to your account</NavLink></div>
          {resetPasswordError && <div className="login-error">{resetPasswordError}</div>}
          </>}
        </div>
        </form>

    </div>
  );
}

// custom hook to manage the form input
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  }
};


export default Reset;
