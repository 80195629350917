import React, {useEffect, useState} from 'react';
import './Rule.scss';
import Action from './Action';
import cx from 'classnames';
import { Dialog, Confirm } from '../../dialogs';
import {CollapsiblePanel, Icons, Form, useForm, Checkbox, MoreMenu} from '../../core';
import {Col, Container, Row} from "reactstrap";
import Editable from "../../editors/Editable";
import general from "../../../utils/general";
import TextField from '@material-ui/core/TextField';
import MetadataAPI from "../../../api/metadata";
import CriteriaEditor from "./CriteriaEditor";

const FORM_EDIT_MODE = {
  Details: 0,
  Criteria: 1,
  Actions: 2
};

const getRuleMenuOptions = () => [
  { text: 'Enable', value: 'TOGGLE_ENABLE' },
  { text: 'Edit rule details', value: 'EDIT_RULE' },
  { text: 'Edit criteria', value: 'EDIT_CRITERIA' },
  { text: 'Delete', value: 'DELETE' }
];

export default ({rule, onChange, onDelete}) => {
  const isNew = !rule.id;
  const [showFormDialog, setShowFormDialog] = useState(isNew);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formEditMode, setFormEditMode] = useState(FORM_EDIT_MODE.Details);
  const [editedValues, setEditedValues] = useState(general.clone(rule));
  const menuOptions = getRuleMenuOptions();
  menuOptions[0].text = editedValues.isDisabled ? 'Enable' : 'Disable';

  const onRuleOptionClick = (option) => {
    switch (option.value) {
      case 'TOGGLE_ENABLE':
        saveValues({ disabled: !editedValues.disabled });
        break;
      case 'EDIT_RULE':
        editRule();
        break;
      case 'EDIT_CRITERIA':
        editCriteria();
        break;
      case 'DELETE':
        setShowDeleteConfirmation(true);
        break;
    }
  };

  const deleteRule = () => {
    setShowDeleteConfirmation(false);
    MetadataAPI.deleteIncidentFlow(rule.id).then(result => {
      if (result.success) {
        // const updatedRule = result.data;
        // updatedRule.disabled = updatedRule.isDisabled;
        // setEditedValues(updatedRule);
        // resetForm(updatedRule);
        // setShowFormDialog(false);
        if (onDelete) {
          onDelete(rule.id);
        }
      }
    });
  };

  const editRule = () => {
    setFormEditMode(FORM_EDIT_MODE.Details);
    setShowFormDialog(true);
  };

  const editCriteria = () => {
    setFormEditMode(FORM_EDIT_MODE.Criteria);
    setShowFormDialog(true);
  };

  const editActions = () => {
    setFormEditMode(FORM_EDIT_MODE.Actions);
    setShowFormDialog(true);
  };

  const validateRule = (fieldValues) => {
    let temp = {...errors}
    if ('name' in fieldValues) {
      if (fieldValues.name.length === 0)
        temp.name = "Required";
      else
        delete temp.name;
    }

    setErrors({
      ...temp
    })
  }

  const {values, isDirty, dirtyFields, updateDirtyFields, errors, setErrors, handleInputChange, resetForm} = useForm(editedValues, true, validateRule);

  const saveRule = (e) => {
    e.preventDefault();
    if (isDirty) {
      if (!Object.keys(errors).length) {
        saveValues(dirtyFields);
      }
    } else {
      setShowFormDialog(false);
    }
  };

  const onCriteriaChange = (criteria) => {
    editedValues.criteria = criteria;
    updateDirtyFields({ criteria });
    setEditedValues(editedValues);
  };

  const saveValues = (values) => {
    if (isNew) {
      values.order = rule.order;
      values.criteria = values.criteria || {};
      values.actions = values.actions || [];
      MetadataAPI.createIncidentFlow(values).then(result => {
        if (result.success) {
          const updatedRule = result.data;
          updatedRule.disabled = updatedRule.isDisabled;
          setEditedValues(updatedRule);
          resetForm(updatedRule);
          setShowFormDialog(false);
          if (onChange) {
            onChange(updatedRule);
          }
        }
      });
    } else {
      MetadataAPI.updateIncidentFlow(rule.id, values).then(result => {
        if (result.success) {
          const updatedRule = result.data;
          updatedRule.disabled = updatedRule.isDisabled;
          setEditedValues(updatedRule);
          resetForm(updatedRule);
          setShowFormDialog(false);
          if (onChange) {
            onChange(updatedRule);
          }
        }
      });
    }
  };

  const onFormCancel = () => {
    if (isNew) {
      if (onDelete) {
        onDelete({});
      }
    } else {
      const ruleValues = general.clone(rule);
      setEditedValues(ruleValues);
      resetForm();
      setShowFormDialog(false);
    }
  };

  let formTitle;
  switch (formEditMode) {
    case FORM_EDIT_MODE.Details:
      formTitle = 'Edit rule details';
      break;
    case FORM_EDIT_MODE.Criteria:
      formTitle = 'Edit criteria';
      break;
    case FORM_EDIT_MODE.Actions:
      formTitle = 'Edit action';
      break;
  }

  return (<React.Fragment>
      <div className={cx('flow', {disabled: editedValues.disabled})}>
        <div className='rule-attributes'>
          <div className='name' onClick={editRule}>{editedValues.name}</div>
          <div>
            <MoreMenu options={menuOptions} onOptionSelected={onRuleOptionClick} />
          </div>
        </div>
        <div className='actions'>
          {rule.actions.map((action, index) => <Action key={`action_${index}`} action={action}/>)}
          <div className='action-add'><Icons.AddCircle/></div>
        </div>
      </div>
      <Dialog
        className='rule-edit-form'
        open={showFormDialog}
        header={formTitle}
      >
        <Form
          onSave={saveRule}
          onCancel={onFormCancel}
        >
          <div className='rule-edit-form-cnt' >
            {formEditMode === FORM_EDIT_MODE.Details &&
            <div className='rule-fields-cnt'>
              <div className='name-row'>
                <div>
                  <TextField label="Name"
                             value={values.name}
                             variant="outlined"
                             size="small"
                             name="name"
                             fullWidth
                             required
                             onChange={handleInputChange}
                             error={!!errors.name}
                             helperText={errors.name}
                  />
                  <Checkbox label="Active"
                            name="disabled"
                            checked={!values.disabled}
                            onChange={handleInputChange}
                  />
                </div>
                <div>
                  {values.lastError &&
                  <div className="error">Last error: {values.lastError}</div>
                  }
                </div>

              </div>
              <div className='options-row'>
                <Checkbox label="Continue processing on success"
                          name="continueProcessingOnSuccess"
                          checked={values.continueProcessingOnSuccess}
                          onChange={handleInputChange}
                />
                <TextField label="Min time between logs"
                           value={values.minTimeBetweenLogs}
                           variant="outlined"
                           size="small"
                           name="minTimeBetweenLogs"
                           placeholder="6h"
                           onChange={handleInputChange}
                           error={!!errors.minTimeBetweenLogs}
                           helperText={errors.minTimeBetweenLogs}
                />
              </div>
              <div>
                <TextField label="Description"
                           value={values.description || ''}
                           variant="outlined"
                           size="small"
                           name="description"
                           fullWidth
                           multiline
                           onChange={handleInputChange}
                           error={!!errors.description}
                           helperText={errors.description}
                />
              </div>
              <div className='edit-criteria-link' >
                <a onClick={(e) => setFormEditMode(FORM_EDIT_MODE.Criteria)}>Edit criteria</a>
              </div>
            </div>
            }
            {formEditMode === FORM_EDIT_MODE.Criteria &&
              <div className='criteria-cnt'>
                <CriteriaEditor initialCriteria={editedValues.criteria} onChange={onCriteriaChange} />
              </div>
            }
            {formEditMode === FORM_EDIT_MODE.Actions &&
            <div>Actions</div>
            }
          </div>
        </Form>
      </Dialog>
      <Confirm open={showDeleteConfirmation} onYes={deleteRule} onClose={(e) => setShowDeleteConfirmation(false)}  >
        Are you sure you want to delete the rule?
      </Confirm>
    </React.Fragment>
  );
};
