import React from 'react';
import Dialog from './Dialog';
import { Buttons } from '../core';

const MsgBox = ({ open, children, title, onClose, className }) => {
  return <Dialog
    open={open}
    header={title}
    onClose={onClose}
    className={className}
    modal={false}
    footer={
      <div>
        <Buttons.PrimaryButton onClick={onClose} >OK</Buttons.PrimaryButton>
      </div>
    }
  >
    {children}
  </Dialog>;
};

export default MsgBox;
