import React from 'react';
import UserAPI from '../../api/users';
import PageSpinner from '../PageSpinner';
import TableForm from '../TableForm';
import ModalManager from "../../utils/modalManager";
import StatusFormatter from '../formatters/StatusFormatter';
import { MdHighlightOff } from 'react-icons/md';
import '../../assets/styles/pages/users.scss';

class Users extends React.Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.roles = this.getRoles();
    this.state = {
      inviteUser: '',
      users: [],
      errors: [],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    return UserAPI.getCurrentUser().then((response) => {
      if (response.success)
        this.currentUser = response.data;
      return UserAPI.getAll().then((response) => {
        if (response.success) {
          this.setState({users: response.data.rows, totalUser: response.data.count, loading: false, message: null});
          return true;
        }
      });
    });
  }

  handleSubmit(e){
    this.inviteCallback();
    e.preventDefault();
  }

  deleteUser(userId){
    this.setState({message: "Deleting..."});
    UserAPI.delete(userId).then(() => {
      this.fetchAll();
    });
    return true;
  }

  openDeleteConfirmationModal(userId) {
    let modalButtons = {
                        "Delete" : { handler: this.deleteUser.bind(this, userId)},
                        "Cancel" : {primary: true}
                       };
    let content = <div>
      Are you sure you want to delete this user?<br />This operation is permanent
    </div>;
    let config = {
        header: "Delete",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  validateEmail(email) {
    const emailTester = new RegExp('^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    return emailTester.test(email.toLowerCase());
  }

  inviteCallback() {
    const username = this.state.inviteUser;
    if (!username)
      return;
    if (this.validateEmail(username)) {
      this.setState({message: 'Saving...'});
      return UserAPI.create({username}).then(() => {
        this.setState({inviteUser: '', message: 'Saved'});
        this.fetchAll();
        return true;
      });
    }
    this.setState({message: "Email is invalid"});
  }

  resendInvitation(email, role) {
    this.setState({inviteUser: email}, () => {
      this.inviteCallback();
      this.showResendSuccess(email, role);
    });
  }

  showResendSuccess(email, role){
    const config = {
        header: "Invite",
        message: <div>An invitation email was resent to {email}</div>,
        handlers: {"Close" : { primary: true}}
    };
    ModalManager.createModal(config);
  }

  getUserStatus(userData){
    if(userData.isPending)
      return {func: this.resendInvitation.bind(this, userData.email, userData.roles)};
    return null;
  }

  saveChanges(valuesToUpdate){
    this.setState({message: "Saving..."});
    return UserAPI.updateMany(valuesToUpdate).then((res) => {
      // TODO: Check if there are errors
      // if (!res)
      //   return false;
      this.fetchAll();
      return true;
    });
  }

  getRoles(){
    // options for role autoComplete editor
    return [
      { value: "user", label: "User" },
      { value: "admin", label: "Admin" }
    ];
  }

  renderStatus(row) {
    return <StatusFormatter value={this.getUserStatus(row)} />;
  }

  getColumns() {
    return [
      {label: "", id: 'delete', type: 'action', title: "Delete", icon: MdHighlightOff, allowed: ({id}) => id !== this.currentUser.id, onClick: ({id}) => {if (id !== this.currentUser.id) this.openDeleteConfirmationModal(id); }},
      {label: "Name", id: "name", type: "string", mandatory: true},
      {label: "Email", id: "email", type: "email", mandatory: true},
      {label: "Role", id: "role", type: "select", readonly: ({id}) => id === this.currentUser.id, items: this.roles},
      {label: "Status", id: "isPending", type: "renderer", readonly: true, render: (row) => this.renderStatus(row)},
      {label: "Created Date", id: "createdAt", type: "datetime", readonly: true},
      {label: "Last Login Date", id: "lastLoginDate", type: "datetime", readonly: true}
    ];
  }

  getAddButton() {
    return <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                  <input className="form-control" type="email" name="email" autoComplete="off" placeholder="example@domain.com" value={this.state.inviteUser} onChange={(event) => this.setState({message: null, inviteUser: event.target.value})} required />
                  <span className="input-group-btn">
                    <button className="btn btn-primary" type="submit" disabled={this.state.message} onClick={() => this.inviteCallback()}>Invite</button>
                  </span>
                </div>
              </form>;
  }

  render() {
    return <main title="Users" className="users-page">
      {this.state.loading ? <PageSpinner /> :
      <TableForm Rows={this.state.users} Columns={this.getColumns()} OnSave={(updatedUsers) => this.saveChanges(updatedUsers)} Errors={this.state.errors} LeftToolbarAction={this.getAddButton()} />
      }
    </main>;
  }



}
export default Users;
