import React from 'react';
import { FormControlLabel, Checkbox as MuiCheckbox } from "@material-ui/core";

const Checkbox = ({ label, checked, onChange, disabled, color = 'primary', ...rest }) => {
  return <FormControlLabel
    control={
      <MuiCheckbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        color={color}
        {...rest}
      />
    }
    label={label}
  />
};


export default Checkbox;
