import {
  verifyTokenStarted, verifyUserSuccess, verifyTokenEnd,
  userLoginStarted, userLoginFailure, userLogout,
  registrationStarted, registrationFailure, verifyRegistrationSuccess
} from "../actions/authActions";
import { verifyTokenService, userLoginService, switchToOrganizationService, userLogoutService, getCSRFToken, userRegisterService } from '../api/auth';
import userApi from '../api/users';

// handle verify token
export const verifyTokenAsync = (silentAuth = false) => async dispatch => {
  dispatch(verifyTokenStarted(silentAuth));
  // Fetch CSRF cookie with token which axios later on sends as a header (x-xsrf-token)
  await getCSRFToken();
  const result = await verifyTokenService();
  if (!result.success) {
    dispatch(verifyTokenEnd());
    if ([401, 403].includes(result.statusCode))
      dispatch(userLogout());
    return;
  }

  const {data: user} = await userApi.getCurrentUser();

  if (result.statusCode === 204)
    dispatch(verifyTokenEnd());
  else
    dispatch(verifyUserSuccess(Object.assign(result.data, { user })));
};

// handle user login
export const userLoginAsync = (username, password) => async dispatch => {
  dispatch(userLoginStarted());

  const result = await userLoginService(username, password);

  if (result.error) {
    dispatch(userLoginFailure(result.error));
    return;
  }

  dispatch(verifyUserSuccess(result.data));
};

// handle user login
export const registrationAsync = (username, password, organizationName, timezone) => async dispatch => {
  dispatch(registrationStarted());

  const result = await userRegisterService(username, password, organizationName, timezone);

  if (result.error) {
    dispatch(registrationFailure(result.error));
    return;
  }
  dispatch(verifyRegistrationSuccess(result.data));
};

// handle user logout
export const userLogoutAsync = () => async dispatch => {
  await userLogoutService();
  dispatch(userLogout());
};

export const switchOrganizationAsync = (orgId) => async dispatch => {
  const result = await switchToOrganizationService(orgId);
  if (result.success) {
    dispatch(verifyUserSuccess(result.data));
  }
};
