import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Logo from '../../../assets/img/logo/logo-dark-theme.png';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icons } from '../../../components/core';
import {NavLink} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { userLogoutAsync } from '../../../asyncActions/authAsyncActions';
import SwitchOrganization from '../SwitchOrganization';
import './SideBar.scss';

const menuItems = [
  {type: 'menuitem', to: '/', name: 'Dashboard', exact: true, icon: <Icons.Dashboard />},
  {type: 'menuitem', to: '/flows', name: 'Flows', exact: false, icon: <Icons.Flow />},
  {type: 'menuitem', to: '/flows_new', name: 'Flows_New', exact: false, roles: ['superuser'], icon: <Icons.Flow />},
  {type: 'separator'},
  {type: 'menuitem', to: '/integrations', name: 'Integrations', exact: true, icon: <Icons.Integrations />},
  {type: 'menuitem', to: '/users', name: 'Users', exact: true, roles: ['admin', 'superuser'], icon: <Icons.Users />},
  {type: 'menuitem', to: '/settings', name: 'Settings', exact: true, roles: ['admin', 'superuser'], icon: <Icons.Settings />},
  {type: 'menuitem', to: '/register', name: 'Create a new account', exact: false, roles: ['superuser'], icon: <Icons.Account />},
  {type: 'separator'},
  {type: 'logout', to: '/login', name: 'Logout', exact: true, icon: <Icons.Logout />},
];



export default function SideBar() {
  const { isAuthenticated, user } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const handleLogout = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    dispatch(userLogoutAsync());
  };

  if (!isAuthenticated || !user)
    return null;

  if(window.location.href.includes('/auth/redirect/') ||
    window.location.href.includes('/auth/authorize/'))
    return null;

  const renderMenuItem = (item, index) => {
    if (item.type === 'separator')
      return <Divider key={"separator_" + index} />;
    return <NavLink key={"menu_" + index}
                    exact={item.exact || false}
                    to={item.to}
                    onClick={item.type === 'logout' ? handleLogout : null}
                    className='side-bar-link'
                    activeClassName='active-link'
    >
      <ListItem button>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
      </ListItem>
    </NavLink>;
  };

  const filteredItems = menuItems.filter((item) => !item.roles || item.roles.includes(user.role));

  return (
      <Drawer className="drawer"
        variant="permanent"
        classes={{paper: 'drawer-container'}}
      >
        <div className="drawer-toolbar">
          <img src={Logo} alt="AppGuard"  />
        </div>
        <div className='drawer-content'>
          <SwitchOrganization />
          {filteredItems.map((item, index) =>  renderMenuItem(item, index))}
        </div>
      </Drawer>
  );
}
