import {
  VERIFY_TOKEN_STARTED, VERIFY_TOKEN_END,
  USER_LOGIN_STARTED, USER_LOGIN_FAILURE,
  REGISTRATION_STARTED, REGISTRATION_FAILURE, VERIFY_REGISTRATION_SUCCESS,
  VERIFY_USER_SUCCESS, USER_LOGOUT, UPDATE_USER_INFO
} from "../actions/actionTypes";

// define initial state of auth reducer
const initialState = {
  user: null, // manage the user details
  authLoading: true, // to indicate that the auth API is in progress
  isAuthenticated: false, // consider as a authentication flag
  userLoginLoading: false, // to indicate that the user sign in API is in progress
  loginError: null, // manage the error of the user sign in API
  registrationLoading: false, // to indicate that the registration API is in progress
  registrationError: null, // manage the error of registration in API
};

// update store based on type and payload and return the state
const auth = (state = initialState, action) => {
  switch (action.type) {
    // verify token - started
    case VERIFY_TOKEN_STARTED: {
      const { silentAuth } = action.payload;
      return silentAuth ? {
        ...state
      } : initialState;
    }
    // verify token - ended/failed
    case VERIFY_TOKEN_END: {
      return {
        ...state,
        authLoading: false
      };
    }
    // user login - started
    case USER_LOGIN_STARTED: {
      return {
        ...state,
        loginError: null,
        userLoginLoading: true
      };
    }
    // user login - ended/failed
    case USER_LOGIN_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loginError: error,
        userLoginLoading: false
      };
    }
    // verify token - success
    case VERIFY_USER_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isAuthenticated: true,
        authLoading: false,
        userLoginLoading: false
      };
    }
    // user login - started
    case REGISTRATION_STARTED: {
      return {
        ...state,
        registrationError: null,
        registrationLoading: true
      };
    }
    // verify token - success
    case VERIFY_REGISTRATION_SUCCESS: {
      const { user } = action.payload;
      return {
        ...state,
        user,
        isAuthenticated: true,
        registrationError: null,
        registrationLoading: false
      };
    }
    // user login - ended/failed
    case REGISTRATION_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        registrationError: error,
        registrationLoading: false
      };
    }
    // handle user logout
    case USER_LOGOUT: {
      return {
        ...initialState,
        loginError: null,
        authLoading: false
      };
    }
    case UPDATE_USER_INFO: {
      return {
        ...state,
        user: action.payload.user
      };
    }
    default: {
      return state;
    }
  }
};

export default auth;
