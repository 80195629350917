import React from 'react';
import BaseAction from './BaseAction';

class SendRestAPICallAction extends BaseAction {

  get Methods() {
    return [
      {value: 'POST'},
      {value: 'PUT'},
      {value: 'PATCH'},
      {value: 'GET'},
      {value: 'DELETE'}
    ];
  }

  renderAction(action) {
    return <div>
      {this.renderInput(action, 'URL', 'url')}
      {this.renderSelect(action, 'Method', 'method', this.Methods, 'POST')}
      {this.renderTextarea(action, 'Headers', 'headers')}
      {this.renderTextarea(action, 'Payload', 'data')}
    </div>;
  }
}

export default SendRestAPICallAction;