import React from 'react';
import { MdLink } from "react-icons/md";
import PropTypes from 'prop-types';

class OAuth2Integration extends React.Component {

  authorizeOAuth2() {
    const width = 500;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const win = window.open(`/auth/redirect/${this.props.type}`, 'oauth_login', `width=${width},height=${height},titlebar=no,left=${left},top=${top}`);
    if (this.props.onSuccess) {
      const timer = setInterval(() => {   
        if (win.closed) {
          clearInterval(timer);
          this.props.onSuccess(); 
        }  
      }, 100);
    }
    win.focus();
  }
  
  render() {
    return <div className="jira-integration-form">
      {this.props.data.isValid ? "You are connected..." : 
        <button type="button" onClick={() => this.authorizeOAuth2()}><MdLink size={20} /> <span>Authorize {this.props.type}</span></button>}
    </div>;
  }
}

OAuth2Integration.defaultProps = {
  data: {
    isValid: false
  }
};

OAuth2Integration.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  onSuccess: PropTypes.func
};

export default OAuth2Integration;
