import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import { Icons } from '../../core';
import './CollapsiblePanel.scss';

const CollapsiblePanel = ({ title, expanded, children }) => {
  return <div className='collapsible-panel' >
    <Accordion style={{marginBottom: 20}} expanded={expanded}>
      <AccordionSummary expandIcon={<Icons.Expand />}>
        <Typography >{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  </div>;
};


export default CollapsiblePanel;
