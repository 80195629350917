import axios from 'axios';

class UsersAPI {
  constructor() {
    this.baseUrl = '/users';
  }

  getCurrentUser() {
    return axios.get(`${this.baseUrl}/info`);
  }

  getAll() {
    return axios.get(`${this.baseUrl}/search`);
  }

  getById(id) {
    return axios.get(`${this.baseUrl}/${id}`);
  }

  update(id, data) {
    return axios.patch(`${this.baseUrl}/${id}`, data);
  }

  updateMany(users) {
    return axios.post(`${this.baseUrl}/bulk`, users);
  }

  create(data) {
    return axios.put(`${this.baseUrl}`, data);
  }

  invite(data) {
    return axios.post(`${this.baseUrl}/invite`, data);
  }

  delete(id) {
    return axios.delete(`${this.baseUrl}/${id}`);
  }

  updateOrganizationOptions(orgId, options) {
    return axios.post(`${this.baseUrl}/organization/${orgId}/options`, { options });
  }
}

export default new UsersAPI();
