import React from 'react';
import PropTypes from 'prop-types';
import {Row} from "reactstrap";
import Draggable from '../helpers/Draggable';
import Editable from './Editable';
import general from '../../utils/general';
import './CriteriaDrawer.scss';
import $ from 'jquery';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";


class CriteriaDrawer extends React.Component {

  static propTypes = {
    criteria: PropTypes.object.isRequired,
    onChange: PropTypes.func
  };

  state = {
    criteria: null
  };

  static getDerivedStateFromProps(nextProps, state) {
    return {
      criteria: nextProps.criteria
    }
  }

  renderGroup(group, level = 0, path = '') {
    const allowDelete = level > 0;
    const offset = 30 * level;
    const groupingType = Object.keys(group)[0];
    const children = group[groupingType];
    path = (path ? `${path}_${groupingType}` : groupingType);
    return <Draggable key={path} draggable={allowDelete} index={path} onDrop={this.onDrop} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} onDragClassName="dragged">
            <Row className="criteria-group" style={{marginLeft: offset}}>
               {level > 0 ? <span className="drag-handle">::</span> : null}
               <div>
                 <Select className="select"
                        variant="outlined"
                        size='small'
                         value={groupingType}
                         onChange={(e) => this.onCriteriaValueChange(group, '', path, e)}
                 >
                     <MenuItem value='must'>And</MenuItem>
                     <MenuItem value='should'>Or</MenuItem>
                 </Select>
                 {/*<select className="form-control" value={groupingType} onChange={(e) => this.onCriteriaValueChange(group, '', path, e)}>*/}
                 {/*  <option name='must' value={'must'}>And</option>*/}
                 {/*  <option name='should' value={'should'}>Or</option>*/}
                 {/*</select>*/}
               </div>
               { allowDelete && <strong onClick={() => this.onDeleteRow(path, true)} title='Delete section'>x</strong> }
             </Row>
             {this.renderChildren(children, level + 1, path)}
            {this.renderButtons(group, level, path)}
           </Draggable>;
  }

  renderChildren(items, level = 0, path = '') {
    if (Array.isArray(items)) {
      return items.map((item, itemIndex) => this.renderChildren(item, level, `${path}_${itemIndex}`));
    } else {
      if (items.hasOwnProperty('leftOperand'))
        return this.renderItem(items, level, path);

      return this.renderGroup(items, level, path);
    }
  }

  renderItem(item, level, path) {
    const offset = 30 * level;
    return <Draggable key={path} draggable index={path} onDrop={this.onDrop} onDragStart={this.onDragStart} onDragEnd={this.onDragEnd} onDragClassName="dragged">
          <Row className="simple-criteria" style={{marginLeft: offset}}>
            <span className="drag-handle">::</span>
            {this.renderLeftOperand(item, path)}
            {this.renderOperator(item, path)}
            {this.renderRightOperand(item, path)}
            <strong onClick={() => this.onDeleteRow(path, false)} title='Delete row'>x</strong>
          </Row>
        </Draggable>;
  }

  renderButtons(item, level, path) {
    const offset = 30 * level;
    return <Row key={`row_${path}`} className="criteria-buttons" style={{marginLeft: offset + 70}}>
              <div>
                <button className="form-control" onClick={(e) => this.addSimple(item, path, e)}>+</button>
              </div>
              <div>
                <select className="form-control" value="none" onChange={(e) => this.addGroup(item, path, e)}>
                  <option name='none' value="none">Add Group</option>
                  <option name='must' value={'must'}>And</option>
                  <option name='should' value={'should'}>Or</option>
                </select>
              </div>
            </Row>;
  }

  render() {
    return <div className='criteria-drawer'>
      {JSON.stringify(this.state.criteria)}
      {this.renderChildren(this.state.criteria)}
    </div>;
  }

  renderLeftOperand(item, path) {
    return <div>
      <select className="form-control" value={item.leftOperand} onChange={(e) => this.onCriteriaValueChange(item, 'leftOperand', path, e)}>
        {_fields.map((f, index) => <option key={'f_field_' + index} value={f.value}>{f.label}</option>)}
      </select>
    </div>;
  }

  renderOperator(item, path) {
    return <div>
      <select className="form-control" value={item.operator} onChange={(e) => this.onCriteriaValueChange(item, 'operator', path, e)}>
        {_operators.map((o, index) => <option key={'o_operator_' + index} value={o.value} >{o.label}</option>)}
      </select>
    </div>;
  }

  renderRightOperand(item, path) {
    const operatorDefinition = _operators.find(o => o.value === item.operator);
    if (!operatorDefinition || !operatorDefinition.noRightOperand)
      return <Editable
          type="text"
          name="rightOperand"
          value={item.rightOperand}
          placeholder=""
          onChange={ (e) => this.onCriteriaValueChange(item, 'rightOperand', path, e)} />;
    return null;
  }

  onCriteriaValueChange = (item, fieldName, path, event) => {
    const criteria = general.clone(this.state.criteria);
    if (!fieldName) {
      const criteriaItem = this.getParent(criteria, path);
      const pathParts = path.split('_');
      const groupingType = pathParts[pathParts.length-1];
      criteriaItem[event.target.value] = criteriaItem[groupingType];
      delete criteriaItem[groupingType];
    }
    else {
      const criteriaItem = this.getItemByPath(criteria, path);
      criteriaItem[fieldName] = event.target.value;
    }
    this.updateCriteria(criteria);
  };

  getParent(criteria, path) {
    let split = path.split('_');
    let parent = criteria;
    for (let i=0; i<split.length-1; i++) {
      parent = parent[split[i]];
    }

    return parent;
  }

  getItemByPath(criteria, path) {
    let split = path.split('_');
    let item = criteria;
    for (let i=0; i<split.length; i++) {
      item = item[split[i]];
    }
    return item;
  }

  onDrop = (srcPath, destPath) => {
    const criteria = general.clone(this.state.criteria);
    const draggedItemInfo = this.getItemInfo(criteria, srcPath);
    const dropItemInfo = this.getItemInfo(criteria, destPath);

    if (draggedItemInfo.collectionPath === dropItemInfo.collectionPath) {
      general.reorder(dropItemInfo.collection, draggedItemInfo.index, dropItemInfo.index);
    } else {
      const item = draggedItemInfo.collection.splice(draggedItemInfo.index, 1)[0];
      dropItemInfo.collection.splice(dropItemInfo.index, 0, item);
    }

    const firstKey = Object.keys(criteria)[0];
    criteria[firstKey] = this.removeEmptyArrays(criteria[firstKey]);

    this.updateCriteria(criteria);
  };

  onDragStart = (evt) => {
    $('.save-changes-bar').hide();
  };

  onDragEnd = (evt) => {
    $('.save-changes-bar').show();
  };

  getItemInfo(criteria, path) {
    let split = path.split('_');
    let index = split.pop();
    let isGrouping = false;
    let collectionPath = split.join('_');
    let object = this.getItemByPath(criteria, path);
    if (!this.isNumeric(index)) {
      isGrouping = true;
      object = {[index]: object};
      if (split.length) {
        index = split.pop();
        collectionPath = split.join('_');
      }
    }
    let collection = this.getItemByPath(criteria, collectionPath || index);
    return {
      object,
      collection,
      count: collection.length,
      index,
      path,
      collectionPath,
      isGrouping
    };
  }

  removeEmptyArrays(arr) {
    const result = [];
    let item, firstKey;
    for (let i=0; i<arr.length; i++) {
      item = arr[i];
      firstKey = Object.keys(item)[0];
      if (['must', 'should'].includes(firstKey)) {
        if (Array.isArray(item[firstKey]) && item[firstKey].length) {
          const tmpArray = this.removeEmptyArrays(item[firstKey]);
          if (tmpArray.length) {
            result.push({[firstKey]: tmpArray});
          }
        }
      } else {
        result.push(item);
      }
    }

    return result;
  }

  isNumeric(value) {
    return !Array.isArray(value) && value - parseFloat(value) + 1 >= 0;
  }

  onDeleteRow = (path, isGroup) => {
    const criteria = general.clone(this.state.criteria);
    let split = path.split('_');
    if (isGroup) {
      split.pop();
      path = split.join('_');
    }
    let parent = this.getParent(criteria, path);
    let lastSegment = split[split.length - 1];
    parent.splice(lastSegment, 1);

    const firstKey = Object.keys(criteria)[0];
    criteria[firstKey] = this.removeEmptyArrays(criteria[firstKey]);

    this.updateCriteria(criteria);
  };

  addSimple = (item, path, e) => {
    e.stopPropagation();
    const criteria = general.clone(this.state.criteria);
    const criteriaItem = this.getItemByPath(criteria, path);
    criteriaItem.push({leftOperand: _fields[0].value, operator: _operators[0].value, rightOperand: ''});
    this.updateCriteria(criteria);
  };

  addGroup = (item, path, e) => {
    e.stopPropagation();
    const selectedValue = e.target.value;
    if (selectedValue !== 'none') {
      const criteria = general.clone(this.state.criteria);
      const criteriaItem = this.getItemByPath(criteria, path);
      criteriaItem.push({[selectedValue]: [{leftOperand: _fields[0].value, operator: _operators[0].value, rightOperand: ''}]});
      this.updateCriteria(criteria);
    }
  };

  updateCriteria(criteria) {
    this.setState({ criteria });
    if (this.props.onChange) {
      this.props.onChange(criteria);
    }
  }
}

export default CriteriaDrawer;

// TODO: get fields and operators from server
const _fields = [
  { label: 'Log message', value: '{log.message}'},
  { label: 'Log level', value: '{log.level}'},
  { label: '# of times rule was triggered', value: '{rule.triggered}'}
];

const _operators = [
  { label: 'Equals', value: 'Equals'},
  { label: 'Not equals', value: 'NotEquals'},
  { label: 'Contains', value: 'Contains'},
  { label: 'Not contains', value: 'NotContains'},
  { label: 'Is empty', value: 'IsEmpty', noRightOperand: true},
  { label: 'Is not empty', value: 'IsNotEmpty', noRightOperand: true},
  { label: 'Greater than', value: 'GreaterThan'},
  { label: 'Greater than or equal', value: 'GreaterThanEqual'},
  { label: 'Less than', value: 'LessThan'},
  { label: 'Less than or equal', value: 'LessThanEqual'},
  { label: 'In', value: 'In'},
  { label: 'Not in', value: 'NotIn'},
  { label: 'Starts with', value: 'StartsWith'},
  { label: 'And', value: 'And'},
  { label: 'Or', value: 'Or'}
];
