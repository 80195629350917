import React, {useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MetadataAPI from "../../../api/metadata";
import Rule from './Rule';
import './Flows.scss';
import {IconButton} from "../../core/buttons";
import cx from 'classnames';

let saveOrderTimeout;

export default () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rules, setRules] = useState([]);

  useEffect(() => {
      setIsLoading(true);
      MetadataAPI.getAllIncidentFlows().then(({success, data: rules}) => {
        if (success) {
          rules.forEach(f => {
            f.error = f.lastError
            f.disabled = f.isDisabled;
          });
          setRules(rules);
        }
      }).finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading)
    return <div className='flows'>
      <CircularProgress color='primary' size={30} />
    </div>;

  const onRuleChange = (updatedRule) => {
    setRules([...rules.filter(rule => rule.id && rule.id !== updatedRule.id), updatedRule]);
  };

  const onRuleDelete = (ruleId) => {
    setRules(rules.filter(rule => rule.id && rule.id !== ruleId));
  };

  const swapRuleIndex = (srcIndex, targetIndex) => {
    const rule = activeRules[srcIndex];
    const targetRule = activeRules[targetIndex];
    if (rule && targetRule) {
      const nextOrder = targetRule.order;
      targetRule.order = rule.order;
      rule.order = nextOrder;
      setRules([...rules]);

      clearTimeout(saveOrderTimeout);
      saveOrderTimeout = setTimeout(saveRulesOrder, 1500) ;
    }
  };

  const saveRulesOrder = () => {
    const orderedRules = activeRules.filter(flow => !flow.disabled).sort((a,b) => a.order - b.order);
    for (let i=0; i<orderedRules.length; i++)
      orderedRules[i].order = i;

    MetadataAPI.saveIncidentFlowsBulk(orderedRules.map(r => ({id: r.id, order: r.order})));
  };

  const moveUp = (index) => {
    swapRuleIndex(index, index - 1);
  };

  const moveDown = (index) => {
    swapRuleIndex(index, index + 1);
  };

  const addNewRule = () => {
    const newRule = {
      order: (activeRules.length ? activeRules[activeRules.length-1].order : 0) + 1 ,
      criteria: {},
      actions: []
    };
    setRules([...rules, newRule]);
  };

  const activeRules = rules.filter(flow => !flow.disabled).sort((a,b) => a.order - b.order);
  const disabledRules = rules.filter(flow => flow.disabled).sort((a,b) => a.order - b.order);

  return (
    <div className='flows'>
      <div className='active-rules'>
        <div>Active rules</div>
        {activeRules.map((rule, index) => (
            <div className='active-rule'>
              <IconButton iconName='ArrowUpward' className={cx('arrow', {hidden: index === 0})} onClick={() => moveUp(index)} tooltip='Move up' />
              <IconButton iconName='ArrowDownward' className={cx('arrow', {hidden: index >= activeRules.length - 1})} onClick={() => moveDown(index)} tooltip='Move down' />
              <Rule onChange={onRuleChange} onDelete={onRuleDelete} key={`active_flow_${rule.id}`} rule={rule} />
            </div>
        ))}
        <div className='rule-add'>
          <IconButton iconName='AddCircle' onClick={addNewRule} tooltip='Add new rule' />
        </div>
      </div>
      {!!disabledRules.length && <div className='idle-rule'>
        <div>Disabled rules</div>
        {disabledRules.map((rule) => <Rule onChange={onRuleChange} onDelete={onRuleDelete} key={`disabled_flow_${rule.id}`} rule={rule} />)}
      </div>}
    </div>
  );
};
