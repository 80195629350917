import {
  VERIFY_TOKEN_STARTED, VERIFY_USER_SUCCESS, VERIFY_TOKEN_END,
  USER_LOGIN_STARTED, USER_LOGIN_FAILURE, USER_LOGOUT, UPDATE_USER_INFO,
  REGISTRATION_STARTED, REGISTRATION_FAILURE, VERIFY_REGISTRATION_SUCCESS
} from "./actionTypes";

// verify token - start
export const verifyTokenStarted = (silentAuth = false) => {
  return {
    type: VERIFY_TOKEN_STARTED,
    payload: {
      silentAuth
    }
  }
};

// verify token - end/failure
export const verifyTokenEnd = () => {
  return {
    type: VERIFY_TOKEN_END
  }
};

// user login - start
export const userLoginStarted = () => {
  return {
    type: USER_LOGIN_STARTED
  }
};

// user login - failure
export const userLoginFailure = (error = 'Something went wrong. Please try again later.') => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: {
      error
    }
  }
};

// handle user logout
export const userLogout = () => {
  return {
    type: USER_LOGOUT
  }
};

// verify token - success
export const verifyUserSuccess = ({ user }) => {
  return {
    type: VERIFY_USER_SUCCESS,
    payload: {
      user
    }
  }
};

export const updateUser = (user) => {
  return {
    type: UPDATE_USER_INFO,
    payload: {
      user
    }
  }
};

// registration - start
export const registrationStarted = () => {
  return {
    type: REGISTRATION_STARTED
  }
};

// registration - failure
export const registrationFailure = (error = 'Something went wrong. Please try again later.') => {
  return {
    type: REGISTRATION_FAILURE,
    payload: {
      error
    }
  }
};

// Registration success
export const verifyRegistrationSuccess = ({ user }) => {
  return {
    type: VERIFY_REGISTRATION_SUCCESS,
    payload: {
      user
    }
  }
};
