import React from 'react';
import BaseAction from './BaseAction';

class GetSlackUserByEmailAction extends BaseAction {

  renderAction(action) {
    return <div>
      {this.renderInput(action, 'Email', 'parameters')}
    </div>;
  }
}

export default GetSlackUserByEmailAction;