import React from 'react';
import TableForm from '../TableForm';
import MetadataAPI from "../../api/metadata";
import PageSpinner from "../PageSpinner";
import ModalManager from "../../utils/modalManager";
import IncidentFlowModel from '../../models/IncidentFlow';
import {MdEdit, MdHighlightOff, MdPauseCircleFilled, MdPlayCircleFilled} from 'react-icons/md';
import '../../assets/styles/pages/incident-flows.scss';
import $ from 'jquery';
import { Buttons } from '../core';

class IncidentFlows extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      flows: [],
      errors: []
    };
  }

  componentDidMount() {
    this.fetchAll();
  }

  fetchAll() {
    MetadataAPI.getAllIncidentFlows().then(({success, data: flows}) => {
      if (success) {
        flows.forEach(f => {
          f.error = f.lastError
        });
        this.setState({flows, loading: false});
      }
    });
  }

  toggleEnable(id) {
    const flow = this.state.flows.find(f => f.id === id);
    MetadataAPI.updateIncidentFlow(id, {disabled: !flow.isDisabled}).then((res) => {
      // TODO: replace current flow instead of refetching
      this.fetchAll();
    });
  }

  deleteFlow(id) {
    this.setState({message: "Deleting..."});
    MetadataAPI.deleteIncidentFlow(id).then(() => {
      this.fetchAll();
    });
    return true;
  }

  openDeleteConfirmationModal(id) {
    let modalButtons = {
                        "Delete" : { handler: this.deleteFlow.bind(this, id)},
                        "Cancel" : {primary: true}
                       };
    let content = <div>
      Are you sure you want to delete this flow?<br />This operation is permanent
    </div>;
    let config = {
        header: "Delete",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    ModalManager.createModal(config);
  }

  addNew(newFlow, modalId) {
    if (newFlow.validate()) {
      MetadataAPI.createIncidentFlow(newFlow).then(result => {
        ModalManager.closeModalById(modalId);
        // TODO: instead of fetching add in the order position and update state
        this.fetchAll();
      });
    }
    return false;
  }

  openAddNewModal() {
    const newFlow = new IncidentFlowModel();
    newFlow.order = Math.max(...this.state.flows.map(f => f.order)) + 1;
    let modalId;
    let modalButtons = {
                        "Add" : { primary: true, handler: () => this.addNew(newFlow, modalId)},
                        "Cancel" : {}
                       };

    const onChange = ({target}) => {
      const {name, value} = target;
      newFlow[name] = value;
    };

    let content = <div className="add-incident-flow-form">
      <div>
        <em>Name</em>
        <input type="text" name="name" placeholder="Rule name" onChange={onChange} />
      </div>
    </div>;
    let config = {
        header: "Add",
        message: content,
        footer: "footer",
        handlers: modalButtons
    };
    modalId = ModalManager.createModal(config);
    setTimeout(() => {
      $('.add-incident-flow-form input').focus();
    }, 0);
  }

  saveChanges(flows) {
    return MetadataAPI.saveIncidentFlowsBulk(flows).then(result => {
      // TODO: Replace newly saved rules with current ones in the state
      this.fetchAll();
      return true;
    });
  }

  getMinTimeBetweenLogs() {
    return '6h';
  }

  onEdit(id) {
    this.props.history.push(`/flows/${id}`);
  }

  getColumns() {
    return [
      {label: "", id: 'edit', type: 'action', title: "Edit", icon: MdEdit, onClick: ({id}) => this.onEdit(id)},
      {label: "", id: 'delete', type: 'action', title: "Delete", icon: MdHighlightOff, onClick: ({id}) => this.openDeleteConfirmationModal(id)},
      {label: "", id: 'isDisabled', type: 'toggle', enableTitle: "Start", disableTitle: "Pause", enableIcon: MdPlayCircleFilled, disableIcon: MdPauseCircleFilled, onClick: ({id}) => this.toggleEnable(id)},
      {label: "Order", id: "order", type: "numeric", mandatory: true},
      {label: "# Triggered", id: "triggered", type: "numeric", readonly: true},
      {label: "Min Time", id: "minTimeBetweenLogs", type: "numeric", placeholder: this.getMinTimeBetweenLogs()},
      {label: "Name", id: "name", type: "string", mandatory: true},
      {label: "Description", id: "description", type: "string"},
      {label: "Error", id: "lastError", type: "string", readonly: true}
    ];
  }

  getAddButton() {
    return <Buttons.New text='New rule' onClick={() => this.openAddNewModal()}/>;
    // return <button className="btn btn-primary" onClick={() => this.openAddNewModal()}>+ Add</button>;
  }

  renderInputEditor(row, col) {
    const {type: columnType} = col;

    switch (columnType) {
      case 'renderer':
        return col.render(row, col);

      case 'action':
        return this.getActionTypeColumn(row, col);

      case 'toggle':
        return this.getToggleTypeColumn(row, col);

      case 'json':
      case 'text':
      case 'textarea':
        return this.getJsonTypeColumn(row, col);

      case 'select':
        return this.getSelectTypeColumn(row, col);

      case 'date':
      case 'datetime':
        return this.getDateTypeColumn(row, col);

      default:
        return this.getRegularTypeColumn(row, col);
    }
  }

  renderValueEditor(col, row) {
    return <div key={"row_" + row.id + "_col_" + col.id} onClick={col.type !== 'action' && col.type !== 'toggle' ? this.setEditable.bind(this, row.id, col.id) : null}>
      {this.renderInputEditor(row, col)}
    </div>;
  }


  render() {
    return <main className="incident-flows-page"
        title="Incident Flows"
      >
      {this.state.loading ? <PageSpinner /> : <>
        <TableForm Rows={this.state.flows} Columns={this.getColumns()} OnSave={(flows) => this.saveChanges(flows)} Errors={this.state.errors} LeftToolbarAction={this.getAddButton()} InitialSortBy="order" InitialSortDirection="ASC" />
      </>}
    </main>;
  }


}

export default IncidentFlows;
